import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { BrandFilter, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { AdminFormValidate } from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateUserMutation, useUpdateUserMutation } from 'services/user';
import SelectController from 'components/Form/SelectController';

const CreateCategoryModal = ({ isOpen, onClose, refetch }) => {
  const cancelRef = React.useRef();
  const createUserMutation = useCreateUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(AdminFormValidate),
    defaultValues: {
      username: '',
      role: undefined,
      password: 'Game2024@',
    },
  });

  const handleSuccess = () => {
    toast.showMessageSuccess(`Tạo quản trị viên thành công`);
    refetch?.();
    onClose(ModalType.Add);
  };
  const handleError = error => {
    toast.showMessageError(error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || `Tạo quản trị viên thành công`);
  };
  const onSubmit = async values => {
    createUserMutation.mutate(
      { ...values, role: values.role?.value, ...(values.brand?.value && { brand: values.brand?.value }) },
      {
        onSuccess: () => handleSuccess(),
        onError: error => handleError(error),
      }
    );
  };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">Tạo quản trị viên</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController control={control} isRequired name="username" label="Tên đăng nhập" />
            <SelectController
              label="Chức vụ"
              name="role"
              placeholder="Chọn chức vụ"
              control={control}
              options={[
                {
                  label: 'Quản trị',
                  value: 'admin',
                },
                {
                  label: 'Quản lý',
                  value: 'manager',
                },
              ]}
              isRequired
              styleContainer={{ py: '4' }}
              onChange={e => {
                if (e.value === 'admin') {
                  setValue('brand', undefined);
                }
              }}
            />
            {watch('role')?.value === 'manager' && (
              <SelectController
                label="Hệ thống"
                name="brand"
                placeholder="Chọn hệ thống"
                control={control}
                options={BrandFilter}
                isRequired
                styleContainer={{ pb: '4' }}
              />
            )}
            <InputController
              control={control}
              name="password"
              label="Mật khẩu"
              styleContainer={{
                marginBottom: '15px',
              }}
              readOnly
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              isLoading={createUserMutation.isPending || updateUserMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              {'Tạo'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateCategoryModal;
