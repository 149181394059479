import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { Select } from 'chakra-react-select';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { BrandFilter, DateTypeKeyFilter } from 'constants/common';
import Pagination from 'components/Pagination/Pagination';
import DatePicker from 'components/DatePicker/DatePicker';
import { toast } from 'components/Toast';
import { downloadFile, formatNumber } from 'utils/helpers';
import StatisticsTable from './components/Table';
import { useGetStatisticsByBetMutation, useQueryGetStatisticsByBet } from 'services/statistics';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryGetListGame } from 'services/game';

const Summaries = [
  {
    label: 'Tổng tiền cược',
    key: 'totalBetAmount',
  },
  {
    label: 'Tổng tiền khách thắng',
    key: 'totalWinAmount',
  },
  {
    label: 'Tổng lợi nhuận',
    key: 'totalNetProfitAmount',
  },
];

function BetStatistics() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [gameOptions, setGameOptions] = useState([]);
  const [gameSelected, setGameSelected] = useState(null);
  const [brandSelected, setBrandSelected] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filterDate, setFilterDate] = useState({
    start: null,
    end: null,
  });
  const [searchTitle, setSearchTitle] = useState({
    encodeUsername: '',
    searchKeyword: '',
  });
  const params = useParams();
  const { id } = params || {};

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: listGameData } = useQueryGetListGame();
  const { data: historyData } = useQueryGetStatisticsByBet(
    { ...filter, searchKeyword: filter.searchKeyword, member: id },
    { enabled: isLoggedIn }
  );
  const exportHistoryMutation = useGetStatisticsByBetMutation(
    {
      ...filter,
      isExport: true,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
      searchKeyword: searchTitle.searchKeyword,
      encodeUsername: searchTitle.encodeUsername,
      brand: brandSelected?.value,
      responseType: 'arraybuffer',
    },
    {
      responseType: 'arraybuffer',
    }
  );

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data, 'name'));
    }
  }, [listGameData?.data]);

  const handleSearch = () => {
    setFilter({
      ...filter,
      ...(filterDate?.start && { start: moment(filterDate?.start).startOf('day').toISOString() }),
      ...(filterDate?.end && { end: moment(filterDate?.end).endOf('day').toISOString() }),
      searchKeyword: searchTitle.searchKeyword,
      encodeUsername: searchTitle.encodeUsername,
      brand: brandSelected?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setBrandSelected(null);
    setFilterDate({
      start: null,
      end: null,
    });
    setSearchTitle({
      searchKeyword: '',
      encodeUsername: '',
    });
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const onDownloadTemplate = () => {
    exportHistoryMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'thong-ke-don-cuoc');
        toast.showMessageSuccess('Xuất file excel thành công');
      },
      onError: () => {
        toast.showMessageError('Xuất file excel thất bại');
      },
    });
  };

  const onChangeSearchTitle = type => value => {
    setSearchTitle(prev => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w="100%">
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold" textTransform="uppercase">
                  Thông kê {'>'} đơn cược
                </Text>
              </Flex>
              <Flex flexDirection="column" gap={'20px'} mt="12px">
                <Flex alignItems={'center'} gap={'16px'} flexWrap="wrap">
                  <FormControl w={{ base: 'full', sm: '250px' }}>
                    <FormLabel h={'21px'}>Nhà điều hành</FormLabel>
                    <Select
                      placeholder="Chọn nhà điều hành"
                      isClearable
                      menuShouldBlockScroll
                      value={brandSelected}
                      onChange={e => {
                        setBrandSelected(e);
                      }}
                      options={BrandFilter}
                    />
                  </FormControl>
                  <FormControl w={{ base: 'full', sm: '250px' }}>
                    <FormLabel>Mã đơn cược</FormLabel>
                    <Input value={searchTitle.searchKeyword} onChange={e => onChangeSearchTitle('searchKeyword')(e.target.value)} />
                  </FormControl>
                  <FormControl w={{ base: 'full', sm: '250px' }}>
                    <FormLabel>Tên HV mã hoá</FormLabel>
                    <Input value={searchTitle.encodeUsername} onChange={e => onChangeSearchTitle('encodeUsername')(e.target.value)} />
                  </FormControl>
                  <FormControl w={{ base: 'full', sm: '250px' }}>
                    <FormLabel h={'21px'}>Loại game</FormLabel>
                    <Select
                      placeholder="Chọn loại game"
                      isClearable
                      menuShouldBlockScroll
                      value={gameSelected}
                      onChange={e => {
                        setGameSelected(e);
                      }}
                      options={gameOptions}
                    />
                  </FormControl>
                  <FormControl w={{ base: 'full', sm: '250px' }}>
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Từ ngày
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.start}
                      onChange={date => onChangeDate('start')(date)}
                    />
                  </FormControl>
                  <FormControl w={{ base: 'full', sm: '250px' }}>
                    <FormLabel marginRight={0} fontSize={14} isTruncated>
                      Đến ngày
                    </FormLabel>
                    <DatePicker
                      styleInput={{
                        minWidth: '300px',
                      }}
                      selectedDate={filterDate.end}
                      minDate={filterDate.start}
                      // maxDate={new Date(formatDate(moment(filter.start).add(6, 'days')))}
                      onChange={date => onChangeDate('end')(date)}
                    />
                  </FormControl>
                </Flex>
                <Flex justifyContent="flex-end" gap="12px">
                  <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Cài lại
                    </Text>
                  </Button>
                  <Button bg="#3182ce" color="#fff" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Tìm kiếm
                    </Text>
                  </Button>
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    isLoading={exportHistoryMutation.isPending}
                    onClick={onDownloadTemplate}
                  >
                    Xuất file excel
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Flex mb="14px" gap="12px">
            {Summaries.map((item, index) => (
              <Text key={index} fontWeight="600">{`${item.label}: ${formatNumber(historyData?.summaries?.[item.key] || 0)}`}</Text>
            ))}
          </Flex>
          <Stack overflow={'auto'}>
            <StatisticsTable tableData={historyData?.data || []} />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={historyData?.pagination?.page}
              pageLength={historyData?.pagination?.pageSize}
              totalRecords={historyData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default BetStatistics;
