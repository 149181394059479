import { isJsonString } from 'utils/helpers';
import { CookieStorage } from '../../utils/cookie-storage';
import { StorageKeys } from '../../constants/storage-keys';
import { checkAccessScreen } from 'utils/url';

/**
 *
 * WithAuthorization
 *
 */
function AuthorizationWrapper({ children, allowedRoles, disabled = false, groupPermission, groupName }) {
  const user = CookieStorage.getCookieData(StorageKeys.UserInfo);

  const userRoles = user?.role || [];
  const hasRole = (allowedRoles || []).some(role => userRoles.includes(role));
  const isAccess = checkAccessScreen({ userRoles, groupName, groupPermission });
  
  if (user?.level === '1' || (hasRole && isAccess) || (!allowedRoles && !userRoles.length)) {
    return children;
  } else {
    if (disabled) {
      return children;
    }
    return null;
  }
}

export default AuthorizationWrapper;
