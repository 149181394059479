import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { ModalType } from 'constants/common';
import { FreeSpinSettingFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import NumericInputController from 'components/Form/NumericInputController';
import { useCreateFreeSpinSettingMutation, useUpdateFreeSpinSettingMutation } from 'services/setting';

const SettingFormModal = ({ settingDetail, isOpen, onClose, refetch }) => {
  const params = useParams();
  const { id } = params || {};

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(FreeSpinSettingFormValidate),
    defaultValues: {
      turn: '',
      ratio_reward: '',
    },
  });

  const createSettingMutation = useCreateFreeSpinSettingMutation();
  const updateSettingMutation = useUpdateFreeSpinSettingMutation();

  useEffect(() => {
    if (settingDetail) {
      reset({
        turn: settingDetail?.turn,
        ratio_reward: settingDetail?.ratio_reward,
      });
    }
  }, [settingDetail]);

  const onSubmit = values => {
    if (settingDetail) {
      updateSettingMutation.mutate(
        { ...values, id: settingDetail?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Cập nhật thiết lập thành công');
            onClose(ModalType.Add);
            refetch();
          },
        }
      );

      return;
    }

    createSettingMutation.mutate(
      { ...values, code: id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Thiết lập thành công');
          onClose(ModalType.Add);
          refetch();
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`${settingDetail ? 'Cập nhật' : ''} Thiết lập`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <NumericInputController control={control} name="turn" label="Số lượt quay" isRequired />
            <NumericInputController control={control} name="ratio_reward" label="Tỷ lệ trả thưởng" isRequired styleContainer={{ pt: 4 }} />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button
              colorScheme="blue"
              isLoading={createSettingMutation.isPending || updateSettingMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SettingFormModal;
