import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { ModalType, SymbolOption } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { toast } from 'components/Toast';
import NumericInputController from 'components/Form/NumericInputController';
import { UpdateSymbolRewardValidate } from 'utils/validation';
import { useUpdateSymbolRewardMutation } from 'services/symbol';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';

const UpdateSymbolRewardModal = ({ symbolRewardDetail, isOpen, onClose, refetch }) => {
  const [gameOptions, setGameOptions] = useState([]);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(UpdateSymbolRewardValidate),
    defaultValues: {
      //   game: undefined,
      symbol: undefined,
      symbols_per_line: '',
      reward_multiplier: '',
    },
  });

  const { data: listGameData } = useQueryGetListGame();
  const configSymbolRewardMutation = useUpdateSymbolRewardMutation();

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data));
    }
  }, [listGameData?.data]);

  useEffect(() => {
    if (symbolRewardDetail) {
      reset({
        symbol: SymbolOption.find(item => item.value === symbolRewardDetail?.symbol),
        reward_multiplier: symbolRewardDetail?.reward_multiplier,
        symbols_per_line: symbolRewardDetail?.symbols_per_line,
      });
    }
  }, [symbolRewardDetail, gameOptions]);

  const onSubmit = values => {
    configSymbolRewardMutation.mutate(
      {
        symbolRewardId: symbolRewardDetail?._id,
        reward_multiplier: values?.reward_multiplier,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Cập nhật thiết lập Symbol thành công');
          onClose(ModalType.Update);
          refetch();
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Update);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`${symbolRewardDetail ? 'Cập nhật' : 'Tạo'} thiết lập Symbol`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            {/* <SelectController control={control} name="game" label="Trò chơi" options={gameOptions} isRequired /> */}
            <SelectController
              control={control}
              name="symbol"
              label="Symbol"
              options={SymbolOption}
              isRequired
              isDisabled={symbolRewardDetail}
            />
            <NumericInputController
              control={control}
              name="symbols_per_line"
              label="Số dây"
              styleContainer={{ pt: 4 }}
              isRequired
              isDisabled={symbolRewardDetail}
            />
            <NumericInputController control={control} name="reward_multiplier" label="Trả thưởng" styleContainer={{ pt: 4 }} isRequired />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Update);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={configSymbolRewardMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateSymbolRewardModal;
