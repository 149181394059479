import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getProfile = () => request.get('/api/v1/user/profile');
export const updateProfile = async data => {
  return await request.post('/api/v1/user/change-information', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const register = data => request.post('/api/v1/user/register', data);
export const changePassword = data => request.post('/api/v1/user/change-pw', data);
export const changePasswordVersionV2 = data => request.post('/api/v1/user/change-pw', data);
export const login = data => request.post('/api/v1/user/login', data);
export const bookmarker = (params) => request.get('/api/v1/setting/book-markers-admin', { params });
export const users = (params) => request.get('/api/v1/user/users', { params });
export const createBookmarker = data => request.post('/api/v1/setting/book-markers', data);
export const deleteBookmarker = data => request.post('/api/v1/setting/book-markers/delete', data);
export const createUser = data => request.post('/api/v1/user/register', data);
export const updateUser = data => request.post('/api/v1/user/update', data);
export const resetPassword = data => request.post('/api/v1/user/reset-password', data);
export const deleteUser = data => request.post('/api/v1/user/delete', data);
export const assignPermission = data => request.post('/api/v1/user/assign-permissions', data);
export const exportTempleteMember = (params, headers) => request.get('/api/v1/mission-member/member-histories', {params, ...headers});

// Mutation
export const useRegisterMutation = () => useMutation({ mutationFn: register });
export const useLoginMutation = () => useMutation({ mutationFn: login });
export const useChangePasswordMutation = () => useMutation({ mutationFn: changePassword });
export const useChangePasswordV2Mutation = () => useMutation({ mutationFn: changePasswordVersionV2 });
export const useUpdateProfileMutation = () => useMutation({ mutationFn: updateProfile });
export const useCreateBookmarkerMutation = () => useMutation({ mutationFn: createBookmarker });
export const useDeleteBookmarkerMutation = () => useMutation({ mutationFn: deleteBookmarker });
export const useCreateUserMutation = () => useMutation({ mutationFn: createUser });
export const useUpdateUserMutation = () => useMutation({ mutationFn: updateUser });
export const useDeleteUserMutation = () => useMutation({ mutationFn: deleteUser });
export const useAssignPermissionMutation = () => useMutation({ mutationFn: assignPermission });
export const useResetPasswordUserMutation = () => useMutation({ mutationFn: resetPassword });
export const useExportCoinTemplateMemberMutation = (params, headers) => useMutation({ mutationFn: () => exportTempleteMember(params, headers) });

// Query
export const useQueryUserProfile = (options = {}) => useQuery({ queryKey: ['getUserProfile'], queryFn: () => getProfile(), ...options });
export const useQuerGetUsers = (params, options = {}) => useQuery({ queryKey: ['getUsers', params], queryFn: () => users(params), ...options });
export const useQueryBookMarker = (params, options = {}) => useQuery({ queryKey: ['getBookMarker', params], queryFn: () => bookmarker(params), ...options });
