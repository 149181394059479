import { Box, Flex, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { FaUserLock } from 'react-icons/fa';
import isEmpty from 'lodash/isEmpty';
import { FORMAT_DATE, ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { formatDate } from 'utils/helpers';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useDeleteConfigSymbolMutation } from 'services/symbol';

const SymbolTable = ({ settings, handleUpdateItem, refetch }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();

  const deleteConfigSymbolMutation = useDeleteConfigSymbolMutation();

  const handleDeleteSymbolConfig = async data => {
    const confirmDelete = window.confirm('Bạn có chắc chắn muốn xóa Symbol này không?');
    if (!confirmDelete) {
      return;
    }
    deleteConfigSymbolMutation.mutate(
      { id: data?._id },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Xóa Symbol thành công');
          refetch?.();
        },
        onError: () => {
          toast.showMessageError('Xóa Symbol không thành công');
          refetch?.();
        },
      }
    );
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('bet', {
        header: 'Tỷ lệ cược',
        cell: info => info.getValue()?.bet_amount,
      }),
      columnHelper.accessor('symbol', {
        header: 'Symbol',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('position', {
        header: 'Vị trí',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('rate', {
        header: 'Tỷ lệ xuất hiện',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Ngày tạo',
        cell: info => <Text whiteSpace={'nowrap'}>{formatDate(info.row.original.createdAt, FORMAT_DATE)}</Text>,
      }),
      columnHelper.accessor('action', {
        header: 'Thao tác',
        cell: info => (
          <Flex alignItems="center" gap={1}>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleUpdateItem(info?.row?.original, ModalType.Add);
              }}
            >
              <EditIcon boxSize={4} />
            </IconButton>
            <IconButton
              bg="transparent"
              onClick={() => {
                handleDeleteSymbolConfig(info?.row?.original);
              }}
            >
              <DeleteIcon color="red.400" boxSize={4} />
            </IconButton>
          </Flex>
        ),
      }),
    ],
    [settings]
  );

  const table = useReactTable({
    data: settings || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th key={header.id} w="120px">
                {header.isPlaceholder ? null : (
                  <Box cursor={header.column.getCanSort() ? 'pointer' : 'default'} onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SymbolTable;
