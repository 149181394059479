import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import { useDeleteIPMutation } from 'services/system';

export default function DeleteTextModal({ textDetail, isOpen, onClose, refetch }) {
  const deleteTextMutation = useDeleteIPMutation();

  const onSubmit = () => {
    if (textDetail?._id) {
      deleteTextMutation.mutate(
        {
          id: textDetail?._id,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Xoá IP thành công');
            onClose(ModalType.Delete);
            refetch();
          },
        }
      );
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Delete);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase"></AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Box textAlign="center">{`Bạn có chắc chắn muốn xoá IP này?`}</Box>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <Flex gap="12px">
            <Button colorScheme="blue" isLoading={deleteTextMutation.isPending} onClick={onSubmit}>
              OK
            </Button>
            <Button
              onClick={() => {
                onClose(ModalType.Delete);
              }}
            >
              Không
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
