import { useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import NumericInputController from 'components/Form/NumericInputController';
import { toast } from 'components/Toast';
import { useQueryGetSettingMaxBetSystem, useSettingMaxBetAmountSystemMutation } from 'services/system';
import { SettingMaxBetFormValidate } from 'utils/validation';

export default function SettingMaxBetAmount() {
  const textColor = useColorModeValue('gray.700', 'white');
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SettingMaxBetFormValidate),
  });

  const { data: settingBetData } = useQueryGetSettingMaxBetSystem();
  const settingMaxBetMutation = useSettingMaxBetAmountSystemMutation();

  useEffect(() => {
    reset({
      maximumBetInDay: settingBetData?.data?.maximumBetInDay,
      ratioBigWin: settingBetData?.data?.ratioBigWin,
      ratioSuperWin: settingBetData?.data?.ratioSuperWin,
      ratioMegaWin: settingBetData?.data?.ratioMegaWin,
    });
  }, [settingBetData?.data]);

  const onSubmit = values => {
    settingMaxBetMutation.mutate(
      {
        ...values,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Thiết lập thành công');
        },
      }
    );
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w="100%">
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold" textTransform="uppercase">
                  QUẢN LÝ HỆ THỐNG {'>'} thiết lập tiền cược
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'} w="50%">
            <form>
              <NumericInputController control={control} name="maximumBetInDay" label="Hạn mức cược tối đa trong ngày" isRequired />
              <NumericInputController control={control} name="ratioBigWin" label="Big win" isRequired styleContainer={{ pt: 4 }} />
              <NumericInputController control={control} name="ratioSuperWin" label="Super win" isRequired styleContainer={{ pt: 4 }} />
              <NumericInputController control={control} name="ratioMegaWin" label="Mega win" isRequired styleContainer={{ pt: 4 }} />
            </form>
            <Flex pt="4">
              <Button colorScheme="blue" isLoading={settingMaxBetMutation.isPending} onClick={handleSubmit(onSubmit)}>
                Lưu
              </Button>
            </Flex>
          </Stack>
        </CardBody>
      </Card>
    </Flex>
  );
}
