import { useQuery, useMutation } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getStatisticsByBrand = (params, headers) => request.get('/api/v1/statistic-brand', { params, ...headers });
export const getStatisticsByGame = (params, headers) => request.get('/api/v1/statistic-by-game', { params, ...headers });
export const getStatisticsByBet = (params, headers) => request.get('/api/v1/statistic-by-bet', { params, ...headers });
export const getCashFlowHistory = (params, headers) => request.get('/api/v1/transfer-flow-histories', { params, ...headers });
export const getSummaryStatisticsMember = (params, headers) => request.get('/api/v1/statistic-member', { params, ...headers });

// Query
export const useQueryGetStatisticsByBrand = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_STATISTICS_BY_BRAND', params], queryFn: () => getStatisticsByBrand(params), ...options });
export const useQueryGetStatisticsByGame = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_STATISTICS_BY_GAME', params], queryFn: () => getStatisticsByGame(params), ...options });
export const useQueryGetStatisticsByBet = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_STATISTICS_BY_BET', params], queryFn: () => getStatisticsByBet(params), ...options });
export const useQueryGetCashFlowHistory = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_CASH_FLOW_HISTORY', params], queryFn: () => getCashFlowHistory(params), ...options });
export const useQueryGetSummaryStatisticsMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_SUMMARY_STATISTIC_MEMBER', params], queryFn: () => getSummaryStatisticsMember(params), ...options });

// Mutation
export const useGetStatisticsByBrandMutation = (params, headers) =>
  useMutation({ mutationFn: () => getStatisticsByBrand(params, headers) });
export const useGetStatisticsByGameMutation = (params, headers) => useMutation({ mutationFn: () => getStatisticsByGame(params, headers) });
export const useGetStatisticsByBetMutation = (params, headers) => useMutation({ mutationFn: () => getStatisticsByBet(params, headers) });
export const useGetCashFlowHistoryMutation = (params, headers) => useMutation({ mutationFn: () => getCashFlowHistory(params, headers) });
