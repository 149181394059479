import React, { useEffect } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalType } from 'constants/common';
import NumericInputController from 'components/Form/NumericInputController';
import { SettingMaxBetFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import { useSettingMaxBetAmountMutation } from 'services/member';

const SettingMaxBetAmountModal = ({ memberDetail, isOpen, onClose, refetch }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SettingMaxBetFormValidate),
    defaultValues: {
      maximumBetInDay: 0,
    },
  });

  const settingMaxBetMutation = useSettingMaxBetAmountMutation();

  useEffect(() => {
    reset({
      maximumBetInDay: memberDetail?.maximumBetInDay,
    });
  }, [memberDetail]);

  const onSubmit = values => {
    if (memberDetail?._id) {
      settingMaxBetMutation.mutate(
        {
          ...values,
          memberId: memberDetail?._id,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Thiết lập thành công');
            onClose(ModalType.Setting);
            refetch();
          },
        }
      );
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Setting);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">Thiết lập hạn mức cược tối đa trong ngày</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <NumericInputController control={control} name="maximumBetInDay" label="Hạn mức" isRequired />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <Flex gap="14px">
            <Button colorScheme="blue" isLoading={settingMaxBetMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
            <Button
              onClick={() => {
                onClose(ModalType.Setting);
              }}
            >
              Đóng
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SettingMaxBetAmountModal;
