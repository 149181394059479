import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Menu, MenuList, MenuItem, MenuButton, Button } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { LinkIcon, ViewIcon } from '@chakra-ui/icons';
import { FORMAT_DATE, ModalType, PERMISSIONS } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FaHistory } from 'react-icons/fa';
import { ImCoinDollar } from 'react-icons/im';
import moment from 'moment';
import { IoMdCopy } from 'react-icons/io';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useLoginMemberMockMutation } from 'services/member';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import { toast } from 'components/Toast';

const SizeTable = ({ categorysData, handleUpdateMember, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const { userInfo } = useUserState();
  const [, copy] = useCopyToClipboard();

  const loginMemberMockMutation = useLoginMemberMockMutation();

  const handleRowClick = (ticket, type) => {
    handleUpdateMember(ticket, type);
  };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'STT',
        cell: info => info.row.index + 1,
        size: 50,
        maxSize: 50,
      }),
      columnHelper.accessor('brand', {
        header: 'Nhà điều hành',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('username', {
        header: 'Username',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            {info.getValue() || '--'}
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue());
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      columnHelper.accessor('encodeUsername', {
        header: 'Tên HV mã hoá',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            {info.getValue() || '--'}
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue());
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      columnHelper.accessor('kkLevel', {
        header: 'Level',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        header: 'Thời gian đăng ký',
        cell: info => moment(info.getValue()).format(FORMAT_DATE),
      }),
      columnHelper.accessor('isBlocked', {
        header: 'Trạng thái',
        cell: info => (info.getValue() ? 'Khoá' : 'Không khoá'),
      }),
      columnHelper.accessor('action', {
        header: 'Hành động',
        cell: info => (
          <Flex direction="column" alignItems="center" margin={'auto'}>
            <Button
              size="sm"
              color="blue.500"
              bg="transparent"
              p="0"
              _hover={{
                bg: 'transparent',
                opacity: 0.7,
              }}
              onClick={() => {
                handleRowClick(info?.row?.original, ModalType.Setting);
              }}
            >
              Thiết lập tiền cược
            </Button>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={[PERMISSIONS.BLOCK_MEMBER]}>
              <Button
                size="sm"
                color="blue.500"
                bg="transparent"
                p="0"
                _hover={{
                  bg: 'transparent',
                  opacity: 0.7,
                }}
                onClick={() => {
                  handleRowClick(info?.row?.original, ModalType.Block);
                }}
              >
                {info?.row?.original?.isBlocked ? 'Mở khoá' : 'Khoá'}
              </Button>
            </AuthorizationComponentWrapper>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={[PERMISSIONS.VIEW_WALLET_HISTORY]}>
              {' '}
              <Button
                size="sm"
                color="blue.500"
                bg="transparent"
                p="0"
                _hover={{
                  bg: 'transparent',
                  opacity: 0.7,
                }}
                onClick={() => {
                  history.push(`/admin/member/${info?.row?.original?._id}/wallet-history`);
                }}
              >
                Xem lịch sử ví
              </Button>
            </AuthorizationComponentWrapper>

            <Button
              size="sm"
              color="blue.500"
              bg="transparent"
              p="0"
              _hover={{
                bg: 'transparent',
                opacity: 0.7,
              }}
              onClick={() => {
                history.push(`/admin/member/${info?.row?.original?._id}/history`);
              }}
            >
              Xem lịch sử cược
            </Button>
            <Button
              size="sm"
              color="blue.500"
              bg="transparent"
              p="0"
              _hover={{
                bg: 'transparent',
                opacity: 0.7,
              }}
              onClick={() => {
                loginMemberMockMutation.mutate(
                  { username: info?.row?.original?.username, brand: info?.row?.original?.brand },
                  {
                    onSuccess: res => {
                      window.open(res?.resultUrl);
                    },
                  }
                );
              }}
            >
              Test game
            </Button>
            {/* <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_DETAIL_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Preview)}>
                <ViewIcon cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'ADD_SUBTRACT_COIN_MEMBER'}>
              <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Adjust)}>
                <ImCoinDollar cursor="pointer" boxSize={4} />
              </IconButton>
            </AuthorizationComponentWrapper>
            <IconButton
              bg="transparent"
              onClick={() => {
                loginMemberMockMutation.mutate(
                  { username: info?.row?.original?.username, brand: info?.row?.original?.brand },
                  {
                    onSuccess: res => {
                      window.open(res?.resultUrl);
                    },
                  }
                );
              }}
            >
              <LinkIcon cursor="pointer" boxSize={4} />
            </IconButton> */}
            {/* <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton bg="transparent" isActive={isOpen} as={IconButton}>
                    <IconButton bg="transparent" onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>
                      <FaHistory cursor="pointer" boxSize={4} />
                    </IconButton>
                  </MenuButton>
                  <MenuList>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_RECHARGE_BET_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/members/${info?.row?.original?._id}/histories`);
                        }}
                      >
                        Lịch sử nạp, cược
                      </MenuItem>
                    </AuthorizationComponentWrapper>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_COLLECT_COIN_MEMBER'}>
                      <MenuItem
                        onClick={() => {
                          history.push(`/admin/members/${info?.row?.original?._id}/missions`);
                        }}
                      >
                        Lịch sử nhận xu
                      </MenuItem>
                    </AuthorizationComponentWrapper>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_HISTORY_ORDER_MEMBER'}>
                      <MenuItem onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Block)}>Lịch sử mua đổi hàng</MenuItem>
                    </AuthorizationComponentWrapper>
                    <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'VIEW_ADDRESS_MEMBER'}>
                      <MenuItem onClick={() => handleViewClick(info?.row?.original?._id, ModalType.Detail)}>
                        Thông tin địa chỉ nhận hàng
                      </MenuItem>
                    </AuthorizationComponentWrapper>
                  </MenuList>
                </>
              )}
            </Menu> */}
          </Flex>
        ),
      }),
    ],
    [categorysData]
  );

  const table = useReactTable({
    data: categorysData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th textAlign="center" key={header.id} w="120px" bg="gray.100" textColor="#000">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={6}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td textAlign="center" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default SizeTable;
