import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import CreateSettingModal from './components/CreateSettingModal';
import SettingTable from './components/Table';
import { useQueryGetConfigBet } from 'services/setting';
import UpdateSettingModal from './components/UpdateSettingModal';
import Pagination from 'components/Pagination/Pagination';
import { Select } from 'chakra-react-select';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';

function Settings() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [gameOptions, setGameOptions] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [gameSelected, setGameSelected] = useState(null);
  const [configDetail, setConfigDetail] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isUpdateModalOpen, onOpen: onOpenUpdateModal, onClose: onCloseUpdateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.Update]: onOpenUpdateModal,
    }),
    [onOpenCreateModal, onOpenUpdateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.Update]: onCloseUpdateModal,
    }),
    [onCloseCreateModal, onCloseUpdateModal]
  );

  const { data: configBetData, refetch } = useQueryGetConfigBet({ ...filter }, { enabled: CookieStorage.isAuthenticated() });
  const { data: listGameData } = useQueryGetListGame();

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data, 'name', 'code'));
    }
  }, [listGameData?.data]);

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setConfigDetail(size);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setConfigDetail(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      gameCode: gameSelected?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setSearchTitle('');
    setGameSelected(null);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex direction="column">
              <Flex justifyContent={'space-between'}>
                <Flex direction={'column'}>
                  <Flex direction="column" gap={'30px'}>
                    <Text fontSize="xl" color={textColor} fontWeight="bold">
                      CÀI ĐẶT TRÒ CHƠI {'>'} TẾT NGUYÊN ĐÁN {'>'} THIẾT LẬP TỶ LỆ CƯỢC
                    </Text>
                  </Flex>
                </Flex>
                <Flex>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'10px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel h={'21px'}>Trò chơi</FormLabel>
                      <Select
                        placeholder="Chọn trò chơi"
                        isClearable
                        menuShouldBlockScroll
                        value={gameSelected}
                        onChange={e => {
                          setGameSelected(e);
                        }}
                        options={gameOptions}
                      ></Select>
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <SettingTable settings={configBetData?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
            </Stack>
            {!isEmpty(configBetData?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={configBetData?.pagination?.page}
                  pageLength={configBetData?.pagination?.pageSize}
                  totalRecords={configBetData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && <CreateSettingModal isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />}
      {isUpdateModalOpen && (
        <UpdateSettingModal isOpen={isUpdateModalOpen} configDetail={configDetail} onClose={handelCloseModal} refetch={refetch} />
      )}
    </>
  );
}

export default Settings;
