import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { ModalType } from 'constants/common';
import { useBlockMemberMutation } from 'services/member';
import { toast } from 'components/Toast';

export default function BlockMemberModal({ memberDetail, isOpen, onClose, refetch }) {
  const blockMemberMutation = useBlockMemberMutation();

  const onSubmit = () => {
    if (memberDetail?._id) {
      blockMemberMutation.mutate(
        {
          memberId: memberDetail?._id,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess(`${memberDetail?.isBlocked ? 'Mở khoá' : 'Khoá'} HV thành công`);
            onClose(ModalType.Block);
            refetch();
          },
        }
      );
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Block);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase"></AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Box textAlign="center">{`Bạn có chắc chắn muốn ${memberDetail?.isBlocked ? 'mở khoá' : 'khoá'} HV này?`}</Box>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <Flex gap="12px">
            <Button colorScheme="blue" isLoading={blockMemberMutation.isPending} onClick={onSubmit}>
              OK
            </Button>
            <Button
              onClick={() => {
                onClose(ModalType.Block);
              }}
            >
              Không
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
