import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { BrandFilter, LevelMemberKkOptionFilter, LevelMemberOptionFilter, ModalType, Roles } from 'constants/common';
import HistoriesMemberTable from './components/Table';
// import CreateCategoryModal from './components/CreateCategoryModal';
import Pagination from 'components/Pagination/Pagination';
import { useListMemberMutation, useQueryGetListMember } from 'services/purchase-history';
import { Select } from 'chakra-react-select';
import AddressMember from './components/AddressMember';
import { useQueryGetAddressMember } from 'services/address';
import ListOrderMember from './components/ListOrderMember';
import AdjustCoinModal from './components/AdjustCoinModal';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import { useQueryBookMarker } from 'services/user';
import { useGetListMemberHistoriesMutation, useQueryGetListMemberHistories } from 'services/member';
import { mappingOptionSelect } from 'utils/mapping';
import { useQueryGetListGame } from 'services/game';
import DatePicker from 'components/DatePicker/DatePicker';

function HistoryMember() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [memberOption, setMemberOption] = useState([]);
  const [gameOptions, setGameOptions] = useState([]);
  const [idMember, setIdMember] = useState();
  const [searchTitle, setSearchTitle] = useState('');
  const [memberSelected, setMemberSelected] = useState();
  const [gameSelected, setGameSelected] = useState();
  const [dateSelected, setDateSelected] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: members, refetch } = useQueryGetListMember({ isHistory: true }, { enabled: isLoggedIn });
  const { data: historiesData } = useQueryGetListMemberHistories({ ...filter }, { enabled: isLoggedIn });
  const { data: listGameData } = useQueryGetListGame();
  const exportHistoriesDataMutation = useGetListMemberHistoriesMutation(
    {
      isExport: true,
      searchKeyword: searchTitle,
      member: memberSelected?.value,
      game_id: gameSelected?.value,
      date: dateSelected,
    },
    {
      responseType: 'arraybuffer',
    }
  );
  const { data: bookmarker } = useQueryBookMarker({}, { enabled: isLoggedIn });

  useEffect(() => {
    if (members?.data) {
      setMemberOption(mappingOptionSelect(members?.data, 'username'));
    }
  }, [members?.data]);

  useEffect(() => {
    if (listGameData?.data) {
      setGameOptions(mappingOptionSelect(listGameData?.data));
    }
  }, [listGameData?.data]);

  const getDetailMemberById = useMemo(() => {
    return members?.data.find(member => member._id === idMember);
  }, [idMember]);

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
  };

  const handleCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setIdMember(null);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      searchKeyword: searchTitle,
      member: memberSelected?.value,
      game_id: gameSelected?.value,
      date: dateSelected,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setMemberSelected(null);
    setGameSelected(null);
    setSearchTitle('');
    setDateSelected(null);
  };

  const handleShowMemberDetail = (idMember, modalType) => {
    openModal?.[modalType]?.();
    setIdMember(idMember);
  };

  const onExportHistoriesData = () => {
    exportHistoriesDataMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'member-template');
        toast.showMessageSuccess('Xuất dữ liệu lịch sử thành công');
      },
      onError: () => {
        toast.showMessageError('Xuất dữ liệu lịch sử thất bại');
      },
    });
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'} w={'full'}>
              <Flex justifyContent={'space-between'} gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  Lịch sử chơi của hội viên
                </Text>
                <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onExportHistoriesData}>
                  Xuất dữ liệu
                </Button>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'start'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap="wrap">
                    <FormControl w="300px">
                      <FormLabel>Tìm kiếm Hội Viên</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Hội viên</FormLabel>
                      <Select
                        placeholder="Chọn hội viên"
                        isClearable
                        value={memberSelected}
                        onChange={e => {
                          setMemberSelected(e);
                        }}
                        options={memberOption}
                      ></Select>
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Trò chơi</FormLabel>
                      <Select
                        placeholder="Chọn trò chơi"
                        isClearable
                        value={gameSelected}
                        onChange={e => {
                          setGameSelected(e);
                        }}
                        options={gameOptions}
                      ></Select>
                    </FormControl>
                    <FormControl w="300px">
                      <FormLabel h={'21px'}>Ngày</FormLabel>
                      <DatePicker
                        styleInput={{
                          minWidth: '300px',
                        }}
                        selectedDate={dateSelected}
                        onChange={date => setDateSelected(date)}
                      />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
                {/* <SimpleGrid spacing={5} templateColumns="repeat(auto-fill, minmax(260px, 1fr))">
                  {userInfo?.role === Roles.ADMIN &&
                    bookmarker?.data?.map(item => (
                      <Card bg="blue.100" variant={'elevated'} p={2} key={item._id}>
                        <CardHeader p={0}>
                          <Heading size="md">Hệ thống {item?.bookMarker}</Heading>
                        </CardHeader>
                        <CardBody p={0}>
                          <Text>Tổng xu đang phát hành: {item?.coin} xu</Text>
                        </CardBody>
                      </Card>
                    ))}
                  {userInfo?.role === Roles.MANAGER && bookmarker?.data?.find(item => item?.bookMarker === userInfo?.brand) && (
                    <Card bg="blue.100" variant={'elevated'} p={2}>
                      <CardHeader p={0}>
                        <Heading size="md">
                          Hệ thống {bookmarker?.data?.find(item => item?.bookMarker === userInfo?.brand)?.bookMarker}
                        </Heading>
                      </CardHeader>
                      <CardBody p={0}>
                        <Text>Tổng xu đang phát hành: {bookmarker?.data?.find(item => item?.bookMarker === userInfo?.brand)?.coin} xu</Text>
                      </CardBody>
                    </Card>
                  )}
                </SimpleGrid> */}
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <HistoriesMemberTable
              historiesData={historiesData?.data || []}
              handleUpdateCategory={handleUpdateItem}
              refetch={refetch}
              handleShowMemberDetail={handleShowMemberDetail}
            />
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Pagination
              page={historiesData?.pagination?.page}
              pageLength={historiesData?.pagination?.pageSize}
              totalRecords={historiesData?.pagination?.count}
              onPageChange={(page, pageLength) => {
                setFilter({
                  ...filter,
                  pageSize: pageLength,
                  pageIndex: page - 1,
                });
              }}
            />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HistoryMember;
