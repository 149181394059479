import { useState } from 'react';
import { Button, Flex, Stack, Text, Switch } from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Pagination from 'components/Pagination/Pagination';
import HistoryStatusTable from './components/HistoryStatusTable';
import { useGetHistoryChangeStatusSystem, useGetStatusSystem, useChangeStatusSystem } from 'services/system-status';
import { toast } from 'components/Toast';

const StatusSystem = () => {
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data: statusHistory, refetch } = useGetHistoryChangeStatusSystem(filter);
  const { data: statusSystem, refetch: refetchCheckStatus } = useGetStatusSystem();
  const changeStatusSystem = useChangeStatusSystem();

  const onActionStatus = () => {
    changeStatusSystem.mutate(undefined, {
      onSuccess: res => {
        toast.showMessageSuccess('Thay đổi trạng thái hệ thống thành công');
        refetch?.();
        refetchCheckStatus?.();
      },
      onError: error => toast.showMessageError('Thay đổi trạng thái hệ thống thất bại'),
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" fontWeight="bold" textTransform="uppercase">
                    QUẢN LÝ HỆ THỐNG {'>'} Lịch sử bảo trì hệ thống
                  </Text>
                </Flex>
                <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                  <Stack>
                    <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                      <Text fontSize="md" fontWeight="bold" textTransform={'uppercase'} cursor="pointer">
                        Trạng thái bảo trì hệ thống:
                      </Text>
                      <Switch size="md" isChecked={statusSystem?.data?.status} onChange={e => onActionStatus()} />
                    </Flex>
                  </Stack>
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <HistoryStatusTable historyStatus={statusHistory?.data || []} refetch={refetch} />
            </Stack>
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={statusHistory?.pagination?.page}
                pageLength={statusHistory?.pagination?.pageSize}
                totalRecords={statusHistory?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </>
  );
};
export default StatusSystem;
