import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogOverlay,
    Switch,
    Text,
  } from '@chakra-ui/react';
  import isEmpty from 'lodash/isEmpty';
  import { ModalType, ORDER_STATUS, SHIP_STATUS } from 'constants/common';
  
  import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
  import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
  import React, { useMemo, useState } from 'react';
  
  export default function ListOrderMember({ isOpen, orderDetail, onClose }) {
    const cancelRef = React.useRef();
    const [sorting, setSorting] = useState([]);
    const columnHelper = createColumnHelper();
  
    const columns = useMemo(
      () => [
        columnHelper.accessor('orderNo', {
          header: 'Mã đơn',
          cell: info => info.getValue(),
        }),
        columnHelper.accessor('totalPrice', {
          header: 'Tổng xu quy đổi',
          cell: info => info.getValue(),
        }),
        columnHelper.accessor('status', {
          header: 'Trạng thái đơn',
          cell: info => ORDER_STATUS?.[info.getValue()] || '--',
        }),
        columnHelper.accessor('shipId', {
          header: 'Trạng thái đơn ship',
          cell: info => {
            return <Text>{SHIP_STATUS?.[info.getValue()?.status]}</Text>
        },
        }),
        columnHelper.accessor('memberAddressId', {
          header: 'Địa chỉ',
          cell: info => {
              const record = info?.row?.original;
              return <Text>{record?.shipId?.destAddress}, {record?.shipId?.destWard}, {record?.shipId?.destDistrict}, {record?.shipId?.destCity}</Text>
          },
        }),
      ],
      [orderDetail?.data]
    );
  
    const table = useReactTable({
      data: orderDetail?.data || [],
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: true,
    });
  
    return (
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Block);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'90%'}>
          <AlertDialogHeader textTransform="uppercase">Lịch sử mua đổi hàng</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Table>
              <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <Th key={header.id} w="120px">
                        {header.isPlaceholder ? null : (
                          <Box
                            isTruncated
                            cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                            onClick={header.column.getToggleSortingHandler()}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                          </Box>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {isEmpty(table.getRowModel().rows) ? (
                  <Tr>
                    <Td textAlign="center" colSpan={6}>
                      Không có dữ liệu
                    </Td>
                  </Tr>
                ) : (
                  table.getRowModel().rows.map(row => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                      ))}
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    );
  }
  