import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { GameTypeOption, ModalType } from 'constants/common';
import { TextSystemFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import { useQueryGetListGame } from 'services/game';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateTextSystemMutation, useUpdateTextSystemMutation } from 'services/system';

const CreateTextModal = ({ textDetail, isOpen, onClose, refetch }) => {
  const [gameOptions, setGameOptions] = useState(GameTypeOption);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(TextSystemFormValidate),
    defaultValues: {
      type: '',
      game: undefined,
      typeHowToPlay: '',
      detail: '',
      howToPlay: '',
    },
  });

  const createTextSystemMutation = useCreateTextSystemMutation();
  const updateTextSystemMutation = useUpdateTextSystemMutation();
  const { data: listGameData } = useQueryGetListGame();

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data, 'name'));
    }
  }, [listGameData?.data]);

  useEffect(() => {
    if (!isEmpty(textDetail)) {
      reset({
        type: textDetail?.type,
        game: undefined,
        typeHowToPlay: textDetail?.typeHowToPlay,
        detail: textDetail?.detail,
        howToPlay: textDetail?.howToPlay,
      });
    }
  }, [textDetail, gameOptions]);

  const onSubmit = values => {
    const dataSubmit = {
      ...values,
      game: values.type.value,
    };

    if (textDetail) {
      updateTextSystemMutation.mutate(
        {
          ...dataSubmit,
          id: textDetail?._id,
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Cập nhật text hiển thị thành công');
            onClose(ModalType.Add);
            refetch();
          },
        }
      );
      return;
    }

    createTextSystemMutation.mutate(
      {
        ...dataSubmit,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Tạo text hiển thị thành công');
          onClose(ModalType.Add);
          refetch();
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        {/* <AlertDialogHeader textTransform="uppercase">{`Tạo trò chơi`}</AlertDialogHeader> */}
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <InputController control={control} name="type" label="Thể loại" isRequired />
            <SelectController control={control} name="game" label="Trò chơi" options={gameOptions} isRequired styleContainer={{ pt: 4 }} />
            <InputController control={control} name="typeHowToPlay" label="Cách chơi" isRequired styleContainer={{ pt: 4 }} />
            <InputController
              control={control}
              name="detail"
              label="Chi tiết cách chơi"
              type="textarea"
              isRequired
              rows={4}
              styleContainer={{ pt: 4 }}
            />
            <InputController
              control={control}
              name="howToPlay"
              label="Hướng dẫn chơi"
              type="textarea"
              isRequired
              rows={5}
              styleContainer={{ pt: 4 }}
            />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter justifyContent="center">
          <Flex gap="14px">
            <Button
              colorScheme="blue"
              isLoading={createTextSystemMutation.isPending || updateTextSystemMutation.isPending}
              onClick={handleSubmit(onSubmit)}
            >
              Lưu
            </Button>
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateTextModal;
