import capitalize from 'lodash/capitalize';
import { convertToTitleCase } from 'utils/helpers';

export const ROOT_API = process.env.REACT_APP_API_HOST;
export const TINY_API_KEY = process.env.REACT_APP_TINY_API_KEY;
export const ROOT_APP = process.env.REACT_APP_HOST;
export const ADVERTISER_PAGE_URL = process.env.REACT_APP_ADVERTISER_URL;

export const MAX_PHONE_NUMBER_DIGIT = 10;
export const defaultPassword = 'CrmEmail2023@';
export const MAX_VIDEO_UPLOAD = 1024 * 1024 * 30;
export const Roles = {
  ADMIN: 'admin',
  GUEST: 'guest',
  USER: 'user',
  MANAGER: 'manager',
};

export const ToastStatus = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info',
};

export const EMAIL_REGEX = /^([a-zA-Z0-9])+(([a-zA-Z0-9_.-])*)+([a-zA-Z0-9])@(([a-zA-Z0-9-])+([a-zA-Z0-9])+\.)([a-zA-Z]{2,})((\.+([a-zA-Z]{2,}))*)$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
export const PHONE_REGEX = /^(0|84|\+84|084)\d{9}$/;
export const LINK_REGEX = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const KEY_REGEX = /_/g;
export const CONVERT_UPPERCASE_REGEX = /\b\w/g;
export const URL_REGEX = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const FORMAT_DATE = 'DD-MM-YYYY HH:mm:ss';

export const ContactChannelKey = {
  Facebook: 'facebook',
  Telegram: 'telegram',
  Skype: 'skype',
};

export const ContactChannelOptions = Object.keys(ContactChannelKey).map(key => ({
  label: key,
  value: ContactChannelKey[key],
}));

export const convertEnumToLabelOption = target => {
  return target
    .replace(KEY_REGEX, ' ')
    .toLowerCase()
    .replace(CONVERT_UPPERCASE_REGEX, l => l.toUpperCase());
};

export const TYPE_ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  DETAIL: 'DETAIL',
};

export const TabFeatureProfile = {
  IdentityInfo: 'Identity Info',
  ChangePassword: 'Change Password',
};

export const ModalType = {
  Add: 'add',
  Update: 'update',
  Delete: 'delete',
  ChangeStatus: 'changeStatus',
  EmailBackup: 'emailBackup',
  Import: 'import',
  Preview: 'preview',
  Detail: 'detail',
  ResetPassword: 'resetPassword',
  Assign: 'assign',
  Block: 'block',
  Adjust: 'adjust',
  Setting: 'setting',
};

export const FileImageValid = ['png', 'jpeg', 'jpg'];

export const FileExcelValid = ['xls', 'xlsx'];

export const ORDER_STATUS = {
  WAITING_TO_CONFIRM: 'Đang chờ xác nhận',
  REJECTED: 'Đã hủy',
  PROCESSING: 'Đang xử lý',
  APPROVED: 'Đã duyệt',
  SHIPPING: 'Đang giao',
  SUCCESS: 'Giao thành công',
};

export const OrderStatusOption = Object.keys(ORDER_STATUS).map(key => ({
  label: capitalize(ORDER_STATUS[key]),
  value: key,
}));

export const MISSION_TYPE = {
  RECHARGE_IN_DAY: 'RECHARGE_IN_DAY',
  RECHARGE_IN_WEEK: 'RECHARGE_IN_WEEK',
  BET_IN_DAY: 'BET_IN_DAY',
  BET_IN_WEEK: 'BET_IN_WEEK',
};

export const MISSION_TYPE_SUB = {
  RECHARGE_IN_DAY: 'Nạp trong ngày',
  RECHARGE_IN_WEEK: 'Nạp trong tuần',
  BET_IN_DAY: 'Cược trong ngày',
  BET_IN_WEEK: 'Cược trong tuần',
};

export const MissionOption = Object.keys(MISSION_TYPE_SUB).map(key => ({
  label: capitalize(convertToTitleCase(MISSION_TYPE_SUB[key])),
  value: key,
}));

export const BRAND = {
  '2Q': '2Q',
  '888B': '888B',
  '8DAY': '8DAY',
  // STORE: 'STORE',
};

export const BrandFilter = Object.keys(BRAND).map(key => ({
  label: BRAND[key],
  value: key,
}));

const LEVEL_MEMBER = {
  VIP0: 'NORMAL',
  VIP1: 'COPPER',
  VIP2: 'SILVER',
  VIP3: 'GOLD',
  VIP4: 'DIAMOND',
};

export const LevelMemberKkOptionFilter = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: LEVEL_MEMBER[key],
}));

export const LevelMemberOptionFilter = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: key,
}));

export const LevelMemberOption = Object.keys(LEVEL_MEMBER).map(key => ({
  label: capitalize(LEVEL_MEMBER[key]),
  value: LEVEL_MEMBER[key],
}));

export const SHIP_STATUS = {
  211: 'Đang đi lấy hàng',
  203: 'Lấy hàng không thành công',
  202: 'Lấy hàng thành công',
  301: 'Nhận hàng vào bưu cục Nguồn',
  302: 'Xuất hàng đến trung tâm khai thác (trung tâm chia chọn)',
  303: 'Nhận hàng vào trung tâm khai thác (trung tâm chia chọn)',
  304: 'Xuất hàng khỏi trung tâm khai thác (trung tâm chia chọn)',
  309: 'Nhận hàng vào bưu cục phát hàng',
  601: 'Xuất hàng để đi giao',
  604: 'Giao hàng không thành công',
  666: 'Giao hàng thành công',
  605: 'Xác nhận chuyển hoàn',
  701: 'Xuất hàng khỏi bưu cục phát để trả về',
  702: 'Nhận hàng vào trung tâm khai thác (trung tâm chia chọn) để trả về',
  703: 'Xuất hàng khỏi trung tâm khai thác (trung tâm chia chọn) để trả về',
  704: 'Nhận hàng vào bưu cục trả hàng',
  705: 'Xuất hàng để trả về',
  707: 'Trả hàng không thành công',
  708: 'Trả hàng thành công',
  777: 'Thất lạc',
  1000: 'Hư hỏng (Damage)',
  'customer Cancel': 'Khách hàng hủy đơn',
};

export const GAME_TYPE_MISSION = {
  1: 'Nổ hũ',
  2: 'Game Việt',
  3: 'Bắn cá',
  4: 'Casino',
  5: 'Thể thao',
  6: 'Xổ số',
};
export const TYPE_UPDATE_COIN = {
  ADD_COIN: 'Cộng xu',
  SUBTRACT_COIN: 'Trừ xu',
};

export const GameTypesMissionOption = Object.keys(GAME_TYPE_MISSION).map(key => ({
  label: GAME_TYPE_MISSION[key],
  value: key,
}));

export const PostType = {
  Banner: 'BANNER',
  Rules: 'RULES',
  Community: 'COMMUNITY',
  Terms: 'TERMS',
};

export const AdjustCoinType = {
  AddCoin: 'ADD_COIN',
  SubtractCoin: 'SUBTRACT_COIN',
};

export const AdjustCoinOption = [
  {
    label: 'Cộng',
    value: AdjustCoinType.AddCoin,
  },
  {
    label: 'Trừ',
    value: AdjustCoinType.SubtractCoin,
  },
];
export const PERMISSION_LIST = [
  {
    title: 'Quản lý danh sách quản trị',
    permissions: {
      CREATE_ADMIN: 'Tạo người dùng',
      EDIT_ADMIN: 'Chỉnh sửa người dùng',
      DELETE_ADMIN: 'Xóa người dùng',
      VIEW_LIST_ADMIN: 'Hiển thị danh sách người dùng',
    },
  },
  {
    title: 'Quản lý thống kê theo nhà điều hành',
    permissions: {
      VIEW_BOOKMAKER_ORDER: 'Hiển thị orders theo bookmaker',
      EXPORT_BOOKMAKER_ORDER: 'Trích xuất file excel',
    },
  },
  {
    title: 'Quản lý thống kê theo nhà game',
    permissions: {
      VIEW_GAME_ORDER: 'Hiển thị orders theo game',
      EXPORT_GAME_ORDER: 'Trích xuất file excel',
    },
  },
  {
    title: 'Quản lý thống kê theo người chơi',
    permissions: {
      VIEW_PLAYER_ORDER: 'Hiển thị orders theo người chơi',
      EXPORT_PLAYER_ORDER: 'Trích xuất file excel',
    },
  },
  {
    title: 'Quản lý thống kê theo đơn cược',
    permissions: {
      VIEW_BET_ORDER: 'Hiển thị orders theo đơn cược',
      EXPORT_BET_ORDER: 'Trích xuất file excel',
    },
  },
  {
    title: 'Quản lý lịch sử dòng tiền',
    permissions: {
      VIEW_WALLET_HISTORY: 'Hiển thị lịch sử ví',
      EXPORT_WALLET_HISTORY: 'Trích xuất file excel',
    },
  },
  {
    title: 'Quản lý cài đặt trò chơi',
    permissions: {
      SETUP_BET_RATIO: 'Thiết lập tỉ lệ cược',
      SETUP_SYMBOL_REWARD: 'Trả thưởng symbol',
      SETUP_SYMBOL: 'Thiết lập symbol',
      SETUP_SYMBOL_BONUS: 'Thiết lập symbol bonus',
    },
  },
  {
    title: 'Quản lý hệ thống',
    permissions: {
      SETTING_TEXT: 'Text hiển thị',
      SETTING_GAME: 'Trò chơi',
      SETTING_SIMULATOR: 'Giả lập lượt chơi',
      SETTING_BET_AMOUNT: 'Thiết lập tiền cược',
      MANAGE_SYSTEM: 'Quản lý hệ thống',
      MANAGE_MAINTAIN: 'Trạng thái hệ thống',
    },
  },
  {
    title: 'Quản lý vai trò quản trị',
    permissions: {
      CREATE_PERMISSION: 'Tạo quyền',
      EDIT_PERMISSION: 'Chỉnh sửa quyền',
      DELETE_PERMISSION: 'Xóa quyền',
      VIEW_PERMISSION: 'Hiển thị danh sách quyền',
    },
  },
  {
    title: 'Quản lý người chơi',
    permissions: {
      VIEW_LIST_MEMBER: 'Hiển thị danh sách người chơi',
      EXPORT_MEMBER: 'Trích xuất file excel',
      BLOCK_MEMBER: 'Khóa người chơi',
      CHANGE_NICKNAME_MEMBER: 'Thay đổi nickname người chơi',
    },
  },
  {
    title: 'Quản lý BO whitelist',
    permissions: {
      UPDATE_WHITELIST: 'Chỉnh sửa IP whitelist',
      DELETE_WHITELIST: 'Xóa IP whitelist',
      CREATE_WHITELIST: 'Tạo mới IP whitelist',
      VIEW_WHITELIST: 'Hiển thị IP whitelist theo nhà điều hành',
    },
  },
];
export const PERMISSIONS = {
  VIEW_LIST_ADMIN: 'Hiển thị danh sách người dùng',
  CREATE_ADMIN: 'Tạo người dùng',
  EDIT_ADMIN: 'Chỉnh sửa người dùng',
  DELETE_ADMIN: 'Xóa người dùng',
  VIEW_BOOKMAKER_ORDER: 'Hiển thị orders theo bookmaker',
  EXPORT_BOOKMAKER_ORDER: 'Trích xuất file excel',
  VIEW_GAME_ORDER: 'Hiển thị orders theo game',
  EXPORT_GAME_ORDER: 'Trích xuất file excel',
  VIEW_PLAYER_ORDER: 'Hiển thị orders theo người chơi',
  EXPORT_PLAYER_ORDER: 'Trích xuất file excel',
  VIEW_BET_ORDER: 'Hiển thị orders theo đơn cược',
  EXPORT_BET_ORDER: 'Trích xuất file excel',
  VIEW_WALLET_HISTORY: 'Hiển thị lịch sử ví',
  EXPORT_WALLET_HISTORY: 'Trích xuất file excel',
  SETUP_BET_RATIO: 'Thiết lập tỉ lệ cược',
  SETUP_SYMBOL_REWARD: 'Trả thưởng symbol',
  SETUP_SYMBOL: 'Thiết lập symbol',
  SETUP_SYMBOL_BONUS: 'Thiết lập symbol bonus',
  SETTING_TEXT: 'Text hiển thị',
  SETTING_GAME: 'Trò chơi',
  SETTING_SIMULATOR: 'Giả lập lượt chơi',
  SETTING_BET_AMOUNT: 'Thiết lập tiền cược',
  MANAGE_SYSTEM: 'Quản lý hệ thống',
  MANAGE_MAINTAIN: 'Trạng thái hệ thống',
  CREATE_PERMISSION: 'Tạo quyền',
  EDIT_PERMISSION: 'Chỉnh sửa quyền',
  DELETE_PERMISSION: 'Xóa quyền',
  VIEW_PERMISSION: 'Hiển thị danh sách quyền',
  VIEW_LIST_MEMBER: 'Hiển thị danh sách người chơi',
  EXPORT_MEMBER: 'Trích xuất file excel',
  BLOCK_MEMBER: 'Khóa người chơi',
  CHANGE_NICKNAME_MEMBER: 'Thay đổi nickname người chơi',
  UPDATE_WHITELIST: 'Chỉnh sửa IP whitelist',
  DELETE_WHITELIST: 'Xóa IP whitelist',
  CREATE_WHITELIST: 'Tạo mới IP whitelist',
  VIEW_WHITELIST: 'Hiển thị IP whitelist theo nhà điều hành',
};

export const PermissionOption = Object.keys(PERMISSIONS).map(key => ({
  label: PERMISSIONS[key],
  value: key,
}));
export const PermissionsOption = PERMISSION_LIST.map(p => {
  const item = Object.keys(p.permissions).map(key => ({
    label: p.permissions[key],
    value: key,
  }));
  p.permissions = item;
  return p;
})
export const GameTypeOption = [
  {
    label: 'Tết nguyên đán',
    value: 'TET_NGUYEN_DAN',
  },
  {
    label: 'Ẩm thực VN',
    value: 'AM_THUC_VN',
  },
  {
    label: 'Xin chào VN',
    value: 'XIN_CHAO_VN',
  },
  {
    label: 'Khám phá vũ trụ',
    value: 'KHAM_PHA_VU_TRU',
  },
  {
    label: 'Tiệm tạp hoá',
    value: 'TIEM_TAP_HOA',
  },
];

export const SymbolOption = [
  {
    label: 'Symbol_0',
    value: '0',
  },
  {
    label: 'Symbol_1',
    value: '1',
  },
  {
    label: 'Symbol_2',
    value: '2',
  },
  {
    label: 'Symbol_3',
    value: '3',
  },
  {
    label: 'Symbol_4',
    value: '4',
  },
  {
    label: 'Symbol_5',
    value: '5',
  },
  {
    label: 'Symbol scatter',
    value: 'scatter',
  },
  {
    label: 'Symbol wild',
    value: 'wild',
  },
];

export const DateTypeKeyFilter = [
  {
    label: 'Theo ngày',
    value: 'day',
  },
  {
    label: 'Theo tháng',
    value: 'month',
  },
];

export const TransactionTypeName = {
  DEPOSIT: 'Nạp tiền vào ví',
  WITHDRAW: 'Rút tiền về nhà điều hành',
  SUBTRACT_COIN: 'Chơi game',
  WIN_COIN: 'Chơi game',
};
export const HISTORY_TYPE_VI = {
  DEPOSIT: 'Nạp tiền vào ví',
  WITHDRAW: 'Rút tiền về nhà điều hành',
  SUBTRACT_COIN: 'Trừ tiền cược',
  WIN_COIN: 'Cộng tiền thắng',
  CREATE_WALLET: 'Tạo mới ví',
};
