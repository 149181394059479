import { useQuery, useMutation } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListMemberHistories = (params, headers) => request.get('/api/v1/member-game-histories', { params, ...headers });
export const getHistoriesMemberDetail = params => request.get('/api/v1/member-game-histories-detail', { params });
export const loginMemberMock = data => request.post('/api/v1/member/login-game', data);
export const blockMember = data => request.post('/api/v1/member/block', data);
export const getHistoriesByMember = (params, headers) => request.get('/api/v1/statistic-game', { params, ...headers });
export const getWalletHistoriesByMember = (params, headers) => request.get('/api/v1/transfer-histories', { params, ...headers });
export const settingMaxBetAmount = data => request.post('/api/v1/members/update-max-bet', data);

// Query
export const useQueryGetListMemberHistories = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_MEMBER_HISTORIES', params], queryFn: () => getListMemberHistories(params), ...options });
export const useQueryGetHistoriesMemberDetail = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_HISTORIES_MEMBER_DETAIL', params], queryFn: () => getHistoriesMemberDetail(params), ...options });
export const useQueryGetHistoriesByMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_HISTORIES_BY_MEMBER', params], queryFn: () => getHistoriesByMember(params), ...options });
export const useQueryGetWalletHistoriesByMember = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_WALLET_HISTORIES_BY_MEMBER', params], queryFn: () => getWalletHistoriesByMember(params), ...options });

// Mutation
export const useLoginMemberMockMutation = () => useMutation({ mutationFn: loginMemberMock });
export const useGetListMemberHistoriesMutation = (params, headers) =>
  useMutation({ mutationFn: () => getListMemberHistories(params, headers) });
export const useBlockMemberMutation = () => useMutation({ mutationFn: blockMember });
export const useGetHistoriesByMemberMutation = (params, headers) =>
  useMutation({ mutationFn: () => getHistoriesByMember(params, headers) });
export const useGetWalletHistoriesByMemberMutation = (params, headers) =>
  useMutation({ mutationFn: () => getWalletHistoriesByMember(params, headers) });
export const useSettingMaxBetAmountMutation = () => useMutation({ mutationFn: settingMaxBetAmount });
