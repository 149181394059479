import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';
import { ModalType, SymbolOption } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { toast } from 'components/Toast';
import NumericInputController from 'components/Form/NumericInputController';
import { SettingSymbolFormValidate } from 'utils/validation';
import { useQueryGetConfigBet } from 'services/setting';
import { useCreateConfigSymbolMutation, useUpdateConfigSymbolMutation } from 'services/symbol';

const CreateSettingModal = ({ symbolDetail, isOpen, onClose, refetch }) => {
  const location = useLocation();

  const [betConfigOption, setBetConfigOption] = useState([]);

  const isSymbolBonus = useMemo(() => {
    if (location.pathname) {
      return location.pathname.split('/').pop() === 'symbols-bonus';
    }
    return false;
  }, [location?.pathname]);

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(SettingSymbolFormValidate),
    defaultValues: {
      bet: undefined,
      symbol: undefined,
      position: undefined,
      rate: '',
    },
  });

  const { data: configBetData } = useQueryGetConfigBet();
  const configSymbolMutation = useCreateConfigSymbolMutation();
  const updateConfigSymbolMutation = useUpdateConfigSymbolMutation();

  useEffect(() => {
    if (!isEmpty(configBetData)) {
      setBetConfigOption(configBetData?.data?.map(item => ({ label: `${item?.game_id?.name} - ${item?.bet_amount}`, value: item?._id })));
    }
  }, [configBetData?.data]);

  useEffect(() => {
    if (symbolDetail) {
      reset({
        bet: betConfigOption?.find(item => item.value === symbolDetail?.bet_id),
        symbol: SymbolOption?.find(item => item?.value === symbolDetail?.symbol),
        position: { label: symbolDetail?.position, value: symbolDetail?.position },
        rate: symbolDetail?.rate,
      });
    }
  }, [betConfigOption]);

  const onSubmit = values => {
    const dataSubmit = {
      bet_id: values.bet?.value,
      symbol: values.symbol?.value,
      rate: values?.rate,
      position: values.position?.value,
      ...(isSymbolBonus && { type: 'BONUS' }),
    };

    if (symbolDetail) {
      updateConfigSymbolMutation.mutate(
        { ...dataSubmit, symbolId: symbolDetail?._id },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Cập nhật thiết lập Symbol thành công');
            onClose(ModalType.Add);
            refetch();
          },
        }
      );

      return;
    }

    configSymbolMutation.mutate(dataSubmit, {
      onSuccess: () => {
        toast.showMessageSuccess('Thiết lập Symbol thành công');
        onClose(ModalType.Add);
        refetch();
      },
    });
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`${symbolDetail ? 'Cập nhật' : 'Tạo'} thiết lập Symbol`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController
              control={control}
              name="bet"
              label="Tỷ lệ cược"
              options={betConfigOption}
              isRequired
              isDisabled={!!symbolDetail}
            />
            <SelectController
              control={control}
              name="symbol"
              label="Symbol"
              options={SymbolOption}
              isRequired
              isDisabled={!!symbolDetail}
              styleContainer={{ pt: 4 }}
            />
            <SelectController
              control={control}
              name="position"
              label="Vị trí"
              options={Array.from({ length: 15 }).map((_, index) => ({ label: index, value: index }))}
              styleContainer={{ pt: 4 }}
              isDisabled={!!symbolDetail}
              isRequired
            />
            <NumericInputController control={control} name="rate" label="Tỷ lệ xuất hiện" styleContainer={{ pt: 4 }} isRequired />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={configSymbolMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateSettingModal;
