import React, { useMemo } from 'react';
import { Box, Button, Flex, useColorModeValue, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import BarChart from 'components/Charts/BarChart';

const BarChartOptions = {
  chart: {
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: 'dark',
  },
  xaxis: {
    categories: [],
    labels: {
      style: {
        colors: '#A0AEC0',
        fontSize: '12px',
      },
    },
    // show: true,
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    // show: true,
    color: '#A0AEC0',
    labels: {
      //   show: true,
      style: {
        colors: '#A0AEC0',
        fontSize: '14px',
      },
    },
  },
  fill: {
    colors: '#9ad0f5',
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
      endingShape: 'rounded',
      borderRadius: 0,
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

const SummaryStatisticMemberChart = ({ title = 'Thống kê', labelChart = 'Số liệu', dataChart, redirectUrl }) => {
  const history = useHistory();
  const textColor = useColorModeValue('gray.700', 'white');

  const barChartData = useMemo(() => {
    const qty = dataChart?.length ? [...dataChart]?.reverse().map(item => item?.total || 0) : [];
    const total = qty?.reduce((a, b) => a + b, 0);
    const data = [
      {
        name: labelChart,
        data: qty,
      },
    ];

    return { data, total };
  }, [dataChart, labelChart]);
  const categories = useMemo(() => (dataChart?.length ? [...dataChart]?.reverse()?.map(item => item?._id?.time) : []), [dataChart]);

  const barChartOptions = useMemo(
    () => ({
      ...BarChartOptions,
      chart: {
        ...BarChartOptions.chart,
      },
      xaxis: {
        ...BarChartOptions.xaxis,
        categories,
      },
      yaxis: {
        ...BarChartOptions.yaxis,
      },
    }),
    [categories, barChartData?.total]
  );

  return (
    <Flex direction="column">
      <Text color={textColor} fontSize="lg" fontWeight="bold" textAlign="center">
        {title}
      </Text>
      <Box minH="300px">
        <BarChart chartData={barChartData.data} chartOptions={barChartOptions} />
      </Box>
      {redirectUrl && (
        <Flex mt="12px" justifyContent="center">
          <Button
            bg="#3182ce"
            color="#fff"
            px="10px"
            maxH="40px"
            alignSelf={'end'}
            onClick={() => {
              history.push(redirectUrl);
            }}
          >
            Xem thêm
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default SummaryStatisticMemberChart;
