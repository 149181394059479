import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getConfigBet = params => request.get('/api/v1/bet-configs', { params });
export const createConfigBet = data => request.post('/api/v1/bet-configs/create-multiple', data);
export const updateConfigBet = data => request.post('/api/v1/bet-configs/update', data);
export const deleteConfigBet = data => request.post('/api/v1/bet-configs/delete', data);

export const getFreeSpinSetting = params => request.get('/api/v1/free-games', { params });
export const createFreeSpinSetting = data => request.post('/api/v1/free-games/create', data);
export const updateFreeSpinSetting = data => request.post('/api/v1/free-games/update', data);
export const deleteFreeSpinSetting = data => request.post('/api/v1/free-games/delete', data);

// Query
export const useQueryGetConfigBet = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_BET_SETTINGS', params], queryFn: () => getConfigBet(params), ...options });
export const useQueryFreeSpinSetting = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_FREE_SPIN_SETTINGS', params], queryFn: () => getFreeSpinSetting(params), ...options });

// Mutation
export const useCreateConfigBetMutation = () => useMutation({ mutationFn: createConfigBet });
export const useUpdateConfigBetMutation = () => useMutation({ mutationFn: updateConfigBet });
export const useDeleteConfigBetMutation = () => useMutation({ mutationFn: deleteConfigBet });
export const useCreateFreeSpinSettingMutation = () => useMutation({ mutationFn: createFreeSpinSetting });
export const useUpdateFreeSpinSettingMutation = () => useMutation({ mutationFn: updateFreeSpinSetting });
export const useDeleteFreeSpinSettingMutation = () => useMutation({ mutationFn: deleteFreeSpinSetting });
