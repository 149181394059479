import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { CloseIcon } from '@chakra-ui/icons';
import { GameTypeOption, ModalType } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { ConfigBetFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import NumericInputController from 'components/Form/NumericInputController';
import { useCreateConfigBetMutation, useUpdateConfigBetMutation } from 'services/setting';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';

const CreateSettingModal = ({ configDetail, isOpen, onClose, refetch }) => {
  const [gameOptions, setGameOptions] = useState([]);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(ConfigBetFormValidate),
    defaultValues: {
      game: undefined,
      rates: [{ amount: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rates',
  });

  const { data: listGameData } = useQueryGetListGame();
  const configBetMutation = useCreateConfigBetMutation();
  const updateConfigBetMutation = useUpdateConfigBetMutation();

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data));
    }
  }, [listGameData?.data]);

  const onSubmit = values => {
    const mutation = configDetail?._id ? updateConfigBetMutation : configBetMutation;

    const data = {
      ...(configDetail?._id && { betId: configDetail?._id }),
      ...(!configDetail?._id && { game_id: values?.game?.value }),
      bet_amounts: values?.rates?.map(item => item.amount),
    };

    mutation.mutate(data, {
      onSuccess: () => {
        toast.showMessageSuccess('Tạo trò chơi thành công');
        onClose(ModalType.Add);
        refetch();
      },
    });
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`${configDetail ? 'Cập nhật' : 'Tạo'} thiết lập trò chơi`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController control={control} name="game" label="Trò chơi" options={gameOptions} isRequired />
            {!isEmpty(fields) && (
              <Box>
                {fields.map((field, index) => {
                  return (
                    <Flex gap={4} alignItems="end" key={index}>
                      <NumericInputController
                        control={control}
                        name={`rates.${index}.amount`}
                        label={`Số tiền cược ${index + 1}`}
                        styleContainer={{ pt: 4 }}
                        isRequired
                      />
                      <IconButton
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <CloseIcon boxSize={3} />
                      </IconButton>
                    </Flex>
                  );
                })}
              </Box>
            )}
            <Flex pt={'4'}>
              <Button
                onClick={() => {
                  append({ amount: '' });
                }}
              >
                Thêm tỷ lệ cược
              </Button>
            </Flex>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={configBetMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateSettingModal;
