import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getListGame = params => request.get('/api/v1/gameCodes', { params });
export const createGame = data => request.post('/api/v1/gameCodes', data);
export const updateGame = data => request.post('/api/v1/gameCodes/update', data);
export const deleteGame = data => request.post('/api/v1/gameCodes/delete', data);
export const simulatorPlayGame = data => request.post('/api/v1/games/play-check', data);
export const uploadThumbnailSystem = (data, config) =>
  request.post('/api/v1/posts/upload-image', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });

// Query
export const useQueryGetListGame = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_LIST_GAME', params], queryFn: () => getListGame(params), ...options });

// Mutation
export const useCreateGameMutation = () => useMutation({ mutationFn: createGame });
export const useUpdateGameMutation = () => useMutation({ mutationFn: updateGame });
export const useDeleteGameMutation = () => useMutation({ mutationFn: deleteGame });
export const useGetListGameMutation = params => useMutation({ mutationFn: () => getListGame(params) });
export const useSimulatorPlayGameMutation = () => useMutation({ mutationFn: simulatorPlayGame });
