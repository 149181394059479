import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { FileExcelValid, ModalType } from 'constants/common';
import Pagination from 'components/Pagination/Pagination';
import { downloadFile } from 'utils/helpers';
import { toast } from 'components/Toast';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import SymbolRewardModal from './components/SymbolRewardModal';
import {
  importConfigSymbolReward,
  useExportSymbolRewardTemplateMutation,
  useGetConfigSymbolRewardMutation,
  useQueryGetSymbolReward,
} from 'services/symbol';
import SymbolRewardTable from './components/Table';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';
import { Select } from 'chakra-react-select';
import UpdateSymbolRewardModal from './components/UpdateSymbolRewardModal';

function SymbolReward() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [symbolRewardDetail, setSymbolRewardDetail] = useState(null);
  const [gameOptions, setGameOptions] = useState([]);
  const [gameSelected, setGameSelected] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const inputImportRef = useRef();
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const { isOpen: isUpdateModalOpen, onOpen: onOpenUpdateModal, onClose: onCloseUpdateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
      [ModalType.Update]: onOpenUpdateModal,
    }),
    [onOpenCreateModal, onOpenUpdateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
      [ModalType.Update]: onCloseUpdateModal,
    }),
    [onCloseCreateModal, onCloseUpdateModal]
  );

  const { data: configSymbolData, refetch } = useQueryGetSymbolReward({ ...filter }, { enabled: CookieStorage.isAuthenticated() });
  const { data: listGameData } = useQueryGetListGame();
  const exportTemplateMutation = useExportSymbolRewardTemplateMutation();
  const exportSymbolRewardDataMutation = useGetConfigSymbolRewardMutation(
    {
      isExport: true,
      gameCode: gameSelected?.value,
    },
    {
      responseType: 'arraybuffer',
    }
  );

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data, 'name', 'code'));
    }
  }, [listGameData?.data]);

  const handleUpdateItem = (data, modalType) => {
    openModal?.[modalType]?.();
    setSymbolRewardDetail(data);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSymbolRewardDetail(null);
  };

  const onDownloadTemplate = () => {
    exportTemplateMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'symbol-template');
        toast.showMessageSuccess('Tải mẫu Symbol reward thành công');
      },
      onError: () => {
        toast.showMessageError('Tải mẫu Symbol reward thất bại');
      },
    });
  };

  const handleImportSymbols = async e => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      const extensionFile = file?.name?.split('.')?.pop();

      if (FileExcelValid.includes(extensionFile)) {
        const formData = new FormData();

        formData.append('importFile', file);

        await importConfigSymbolReward(formData)
          .then(() => {
            toast.showMessageSuccess('Tải lên Symbol reward thành công');
            refetch();
          })
          .catch(() => {
            toast.showMessageError('Tải lên Symbol reward thất bại');
          });

        return;
      }

      setError('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
    }
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      gameCode: gameSelected?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setGameSelected(null);
  };

  const onDownloadSymbolRewardData = () => {
    exportSymbolRewardDataMutation.mutate(undefined, {
      onSuccess: response => {
        downloadFile(response, 'symbol-reward-data');
        toast.showMessageSuccess('Xuất dữ liệu Symbol thành công');
      },
      onError: () => {
        toast.showMessageError('Xuất dữ liệu Symbol thất bại');
      },
    });
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex direction="column">
              <Flex justifyContent={'space-between'}>
                <Flex direction={'column'}>
                  <Flex direction="column" gap={'30px'}>
                    <Text fontSize="xl" color={textColor} fontWeight="bold" textTransform="uppercase">
                      CÀI ĐẶT TRÒ CHƠI {'>'} TẾT NGUYÊN ĐÁN {'>'} trả thưởng Symbol
                    </Text>
                  </Flex>
                </Flex>
                <Flex gap="16px">
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadTemplate}>
                    Tải template
                  </Button>
                  <input type="file" hidden ref={inputImportRef} onChange={handleImportSymbols} />
                  <Button
                    bg="#3182ce"
                    color="#fff"
                    _hover={{ bg: '#67a1d7' }}
                    onClick={() => {
                      inputImportRef?.current?.click();
                    }}
                  >
                    Tải lên thiết lập Symbol
                  </Button>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} isLoading={false} onClick={onDownloadSymbolRewardData}>
                    Xuất file excel
                  </Button>
                  <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_ADMIN'}>
                    <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Thêm
                      </Text>
                    </Button>
                  </AuthorizationComponentWrapper>
                </Flex>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'10px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel h={'21px'}>Trò chơi</FormLabel>
                      <Select
                        placeholder="Chọn trò chơi"
                        isClearable
                        menuShouldBlockScroll
                        value={gameSelected}
                        onChange={e => {
                          setGameSelected(e);
                        }}
                        options={gameOptions}
                      />
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <SymbolRewardTable settings={configSymbolData?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
            </Stack>
            {!isEmpty(configSymbolData?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={configSymbolData?.pagination?.page}
                  pageLength={configSymbolData?.pagination?.pageSize}
                  totalRecords={configSymbolData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && <SymbolRewardModal isOpen={isCreateModalOpen} onClose={handelCloseModal} refetch={refetch} />}
      {isUpdateModalOpen && (
        <UpdateSymbolRewardModal
          symbolRewardDetail={symbolRewardDetail}
          isOpen={isUpdateModalOpen}
          onClose={handelCloseModal}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default SymbolReward;
