import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileImageValid, GameTypeOption, ModalType, ROOT_API } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { GameCodeFormValidate } from 'utils/validation';
import { toast } from 'components/Toast';
import { uploadThumbnailSystem, useCreateGameMutation, useUpdateGameMutation } from 'services/game';
import FileSelectController from 'components/Form/FileSelectController';
import { isEmpty } from 'lodash';
import { formatImageUrl } from 'utils/url';

const CreateGameModal = ({ games, gameDetail, isOpen, onClose, refetch }) => {
  const [gameOptions, setGameOptions] = useState(GameTypeOption);
  const [fileSelected, setFileSelected] = useState({
    thumbnail: null,
    thumbnail_h5: null,
  });
  const [error, setError] = useState({
    thumbnail: null,
    thumbnail_h5: null,
  });

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(GameCodeFormValidate),
  });

  const createGameMutation = useCreateGameMutation();
  const updateGameMutation = useUpdateGameMutation();

  useEffect(() => {
    const newGameOption = GameTypeOption.filter(item => {
      const gameExists = games?.find(itemGame => itemGame?.code === item.value);

      if (!gameExists) return item;
    });

    setGameOptions(newGameOption);
  }, [games]);

  useEffect(() => {
    if (!isEmpty(gameDetail)) {
      reset({
        type: gameOptions.find(item => item.value === gameDetail?.code),
      });
      setFileSelected({
        thumbnail: { ...(gameDetail?.thumbnail && { filePreview: formatImageUrl(gameDetail?.thumbnail) }) },
        thumbnail_h5: { ...(gameDetail?.thumbnail_h5 && { filePreview: formatImageUrl(gameDetail?.thumbnail_h5) }) },
      });
    }
  }, [gameDetail, gameOptions]);

  const onUploadPhoto = async () => {
    const formData = new FormData();
    let storePhoto = {
      thumbnail: fileSelected?.thumbnail?.filePreview?.replace(ROOT_API + '/', '') || fileSelected?.thumbnail,
      thumbnail_h5: fileSelected?.thumbnail_h5?.filePreview?.replace(ROOT_API + '/', '') || fileSelected?.thumbnail_h5,
    };

    if (!!fileSelected?.thumbnail?.file) {
      formData.append('thumbnailFile', fileSelected?.thumbnail?.file);

      const response = await uploadThumbnailSystem(formData);
      storePhoto.thumbnail = response?.data;
    }

    if (!!fileSelected?.thumbnail_h5?.file) {
      formData.delete('thumbnailFile');
      formData.append('thumbnailFile', fileSelected?.thumbnail_h5?.file);

      const response = await uploadThumbnailSystem(formData);
      storePhoto.thumbnail_h5 = response?.data;
    }
    return storePhoto;
  };

  const onSubmit = async values => {
    const photosPath = await onUploadPhoto();

    if (!photosPath.thumbnail) {
      setError({ ...error, thumbnail: 'Vui lòng tải ảnh lên' });
      return;
    }

    if (!photosPath.thumbnail_h5) {
      setError({ ...error, thumbnail_h5: 'Vui lòng tải ảnh lên' });
      return;
    }

    const dataSubmit = {
      name: values.type.label,
      code: values.type.value,
      ...(photosPath.thumbnail && { thumbnail: photosPath.thumbnail }),
      ...(photosPath.thumbnail_h5 && { thumbnail_h5: photosPath.thumbnail_h5 }),
    };

    if (gameDetail) {
      updateGameMutation.mutate(
        {
          game_id: gameDetail?._id,
          ...(photosPath.thumbnail && { thumbnail: photosPath.thumbnail }),
          ...(photosPath.thumbnail_h5 && { thumbnail_h5: photosPath.thumbnail_h5 }),
        },
        {
          onSuccess: () => {
            toast.showMessageSuccess('Cập nhật trò chơi thành công');
            onClose(ModalType.Add);
            refetch();
          },
        }
      );
      return;
    }

    createGameMutation.mutate(dataSubmit, {
      onSuccess: () => {
        toast.showMessageSuccess('Tạo trò chơi thành công');
        onClose(ModalType.Add);
        refetch();
      },
    });
  };

  const handleFileSelect = (fieldName, e) => {
    if (e?.target?.files?.[0]) {
      const fileSelected = e.target.files[0];
      const filePreview = URL.createObjectURL(fileSelected);
      const extensionFile = fileSelected?.name?.split('.')?.pop()?.toLowerCase();

      if ([...FileImageValid].includes(extensionFile)) {
        setFileSelected(prev => ({
          ...prev,
          [fieldName]: { file: fileSelected, filePreview },
        }));

        return;
      }

      setFileSelected(prev => ({
        ...prev,
        [fieldName]: null,
      }));
      handleErrorFile(fieldName, 'File chỉ hỗ trợ định dạng .png, .jpeg, .jpg');
    }
  };

  const handleErrorFile = (fieldName, value) => {
    setError(prev => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`Tạo trò chơi`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController
              control={control}
              name="type"
              label="Trò chơi"
              options={gameOptions}
              isRequired
              styleContainer={{ pt: 4 }}
              isDisabled={gameDetail}
            />
            <FileSelectController
              label="Thumbnail PC"
              name="thumbnail"
              imgWidth="100vw"
              filePreview={fileSelected.thumbnail?.filePreview}
              error={error.thumbnail}
              isRequired
              // showChangeBtn={missionDetailData?.data ? false : true}
              // showChooseBtn={missionDetailData?.data ? false : true}
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
            <FileSelectController
              label="Thumbnail H5"
              name="thumbnail_h5"
              imgWidth="100vw"
              filePreview={fileSelected.thumbnail_h5?.filePreview}
              error={error.thumbnail_h5}
              isRequired
              // showChangeBtn={missionDetailData?.data ? false : true}
              // showChooseBtn={missionDetailData?.data ? false : true}
              handleFileSelect={handleFileSelect}
              handleErrorFile={handleErrorFile}
            />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={createGameMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CreateGameModal;
