import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import { ModalType, SymbolOption } from 'constants/common';
import SelectController from 'components/Form/SelectController';
import { toast } from 'components/Toast';
import NumericInputController from 'components/Form/NumericInputController';
import { SymbolRewardValidate } from 'utils/validation';
import { useCreateSymbolRewardMutation } from 'services/symbol';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';

const SymbolRewardModal = ({ symbolRewardDetail, isOpen, onClose, refetch }) => {
  console.log("🚀 ~ SymbolRewardModal ~ symbolRewardDetail:", symbolRewardDetail)
  const [gameOptions, setGameOptions] = useState([]);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(SymbolRewardValidate),
    defaultValues: {
      game: undefined,
      symbol: undefined,
      data: [
        { symbols_per_line: '3', reward_multiplier: '' },
        { symbols_per_line: '4', reward_multiplier: '' },
        { symbols_per_line: '5', reward_multiplier: '' },
      ],
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'data',
  });

  const { data: listGameData } = useQueryGetListGame();
  const configSymbolRewardMutation = useCreateSymbolRewardMutation();

  useEffect(() => {
    if (!isEmpty(listGameData?.data)) {
      setGameOptions(mappingOptionSelect(listGameData?.data));
    }
  }, [listGameData?.data]);

  const onSubmit = values => {
    configSymbolRewardMutation.mutate(
      {
        game_id: values.game?.value,
        symbol: values.symbol?.value,
        data: values.data,
      },
      {
        onSuccess: () => {
          toast.showMessageSuccess('Thiết lập Symbol thành công');
          onClose(ModalType.Add);
          refetch();
        },
      }
    );
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Add);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">{`${symbolRewardDetail ? 'Cập nhật' : 'Tạo'} thiết lập Symbol`}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <SelectController control={control} name="game" label="Trò chơi" options={gameOptions} isRequired />
            <SelectController control={control} name="symbol" label="Symbol" options={SymbolOption} isRequired styleContainer={{ pt: 4 }} />
            <Box>
              {fields.map((field, index) => {
                return (
                  <Flex gap={4} alignItems="end" key={index}>
                    <NumericInputController
                      control={control}
                      name={`data.${index}.reward_multiplier`}
                      label={`Trả thưởng cho ${field?.symbols_per_line} dây`}
                      styleContainer={{ pt: 4 }}
                      isRequired
                    />
                  </Flex>
                );
              })}
            </Box>
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={configSymbolRewardMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default SymbolRewardModal;
