import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalType } from 'constants/common';
import { toast } from 'components/Toast';
import NumericInputController from 'components/Form/NumericInputController';
import { useUpdateConfigBetMutation } from 'services/setting';
import { UpdateConfigBetFormValidate } from 'utils/validation';

const UpdateSettingModal = ({ configDetail, isOpen, onClose, refetch }) => {
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(UpdateConfigBetFormValidate),
    defaultValues: {
      bet_amount: '',
    },
  });

  const updateConfigBetMutation = useUpdateConfigBetMutation();

  useEffect(() => {
    if (!!configDetail) {
      reset({ bet_amount: configDetail?.bet_amount });
    }
  }, [configDetail]);

  const onSubmit = values => {
    const data = {
      ...(configDetail?._id && { betId: configDetail?._id }),
      bet_amount: values?.bet_amount,
    };

    updateConfigBetMutation.mutate(data, {
      onSuccess: () => {
        toast.showMessageSuccess('Cập nhật thiết lập thành công');
        onClose(ModalType.Update);
        refetch();
      },
    });
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose(ModalType.Update);
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">Cập nhật thiết lập trò chơi</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <form>
            <NumericInputController control={control} name="bet_amount" label={`Số tiền cược`} isRequired />
          </form>
        </AlertDialogBody>
        <AlertDialogFooter>
          <Flex gap="14px">
            <Button
              onClick={() => {
                onClose(ModalType.Update);
              }}
            >
              Đóng
            </Button>
            <Button colorScheme="blue" isLoading={updateConfigBetMutation.isPending} onClick={handleSubmit(onSubmit)}>
              Lưu
            </Button>
          </Flex>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default UpdateSettingModal;
