import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Flex,
  Box,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { ModalType } from 'constants/common';
import { formatDate } from 'utils/helpers';

const BetDetailModal = ({ betDetail, isOpen, onClose }) => {
  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
      isCentered
      size="2xl"
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader textTransform="uppercase">Chi tiết đơn cược</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Flex gap="4px">
            <Text fontWeight={600}>Mã đơn cược:</Text>
            <Text>{betDetail?._id?.game_id}</Text>
          </Flex>
          <Flex gap="4px">
            <Text fontWeight={600}>Trạng thái:</Text>
            <Text>Đã đóng</Text>
          </Flex>
          <Flex gap="4px">
            <Text fontWeight={600}>Thời gian đặt cược:</Text>
            <Text>
              {formatDate(betDetail.played_at, 'HH:mm:ss')} - {formatDate(betDetail.played_at, 'DD/MM/YYYY')}
            </Text>
          </Flex>
          <Flex gap="4px">
            <Text fontWeight={600}>Thời gian kết thúc:</Text>
            <Text>
              {formatDate(betDetail.played_at, 'HH:mm:ss')} - {formatDate(betDetail.played_at, 'DD/MM/YYYY')}
            </Text>
          </Flex>
          <Box>-----------------</Box>
          <Flex gap="4px">
            <Text fontWeight={600}>Tiền đặt cược:</Text>
            <Text>{betDetail?.totalBetAmount}</Text>
          </Flex>
          <Flex gap="4px">
            <Text fontWeight={600}>Tiền nhận lại:</Text>
            <Text>{betDetail?.totalWinAmount}</Text>
          </Flex>
          <Box>-----------------</Box>
          <Flex gap="4px">
            <Text fontWeight={600}>Thể loại:</Text>
            <Text></Text>
          </Flex>
          <Flex gap="4px">
            <Text fontWeight={600}>Game:</Text>
            <Text>{betDetail?.gameName}</Text>
          </Flex>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default BetDetailModal;
