import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Button } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ViewIcon } from '@chakra-ui/icons';
import { formatDate, formatNumber } from 'utils/helpers';
import { GAME_TYPE_MISSION, ModalType } from 'constants/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BiCommentDetail } from 'react-icons/bi';
import moment from 'moment';

const StatisticsTable = ({ tableData, handleUpdateCategory, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const columnHelper = createColumnHelper();
  const history = useHistory();

  // const handleRowClick = (ticket, type) => {
  //   handleUpdateCategory(ticket, type);
  // };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'STT',
        cell: info => info.row.index + 1,
      }),
      columnHelper.accessor('brand', {
        header: 'Nhà điều hành',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('createdAt', {
        header: 'Thời gian',
        cell: info => formatDate(info.getValue(), 'DD-MM-YYYY'),
      }),
      columnHelper.accessor('totalMember', {
        header: 'Tổng user',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('totalTurn', {
        header: 'Đơn cược',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('totalBetAmount', {
        header: 'Tiền cược',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('totalWinAmount', {
        header: 'Tiền khách thắng',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('totalNetProfitAmount', {
        header: 'Lợi nhuận',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('rateWin', {
        header: '% thắng',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      // columnHelper.accessor('action', {
      //   header: 'Hành động',
      //   cell: info => (
      //     <Flex direction="column" alignItems="center" margin={'auto'}>
      //       <Button
      //         size="sm"
      //         color="blue.500"
      //         bg="transparent"
      //         p="0"
      //         _hover={{
      //           bg: 'transparent',
      //           opacity: 0.7,
      //         }}
      //       >
      //         Thống kê theo game
      //       </Button>
      //     </Flex>
      //   ),
      // }),
    ],
    [tableData]
  );

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Table>
      <Thead>
        {table.getHeaderGroups().map(headerGroup => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <Th textAlign="center" key={header.id} minW="120px" bg="gray.100" textColor="#000">
                {header.isPlaceholder ? null : (
                  <Box
                    isTruncated
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted()] ?? null}
                  </Box>
                )}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {isEmpty(table.getRowModel().rows) ? (
          <Tr>
            <Td textAlign="center" colSpan={9}>
              Không có dữ liệu
            </Td>
          </Tr>
        ) : (
          table.getRowModel().rows.map(row => (
            <Tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <Td textAlign="center" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  );
};

export default StatisticsTable;
