import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/request';

export const getConfigSymbol = (params, headers) =>
  request.get('/api/v1/symbol-configs', {
    params,
    ...headers,
  });
export const exportSymbolTemplate = data => request.post('/api/v1/symbol-configs/export-template', data, { responseType: 'arraybuffer' });
export const importConfigSymbol = (data, config) =>
  request.post('/api/v1/symbol-configs/import-symbol', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const createConfigSymbol = data => request.post('/api/v1/symbol-configs', data);
export const updateConfigSymbol = data => request.post('/api/v1/symbol-configs/update', data);
export const deleteConfigSymbol = data => request.post('/api/v1/symbol-configs/delete', data);

export const getSymbolReward = (params, headers) => request.get('/api/v1/symbol-rewards', { params, ...headers });
export const exportSymbolRewardTemplate = data =>
  request.post('/api/v1/symbol-rewards/export-template', data, { responseType: 'arraybuffer' });
export const importConfigSymbolReward = (data, config) =>
  request.post('/api/v1/symbol-rewards/import-symbol-reward', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  });
export const createSymbolReward = data => request.post('/api/v1/symbol-rewards', data);
export const updateSymbolReward = data => request.post('/api/v1/symbol-rewards/update', data);
export const deleteSymbolReward = data => request.post('/api/v1/symbol-rewards/delete', data);

// Mutation
export const useCreateConfigSymbolMutation = () => useMutation({ mutationFn: createConfigSymbol });
export const useUpdateConfigSymbolMutation = () => useMutation({ mutationFn: updateConfigSymbol });
export const useDeleteConfigSymbolMutation = () => useMutation({ mutationFn: deleteConfigSymbol });
export const useExportSymbolTemplateMutation = () => useMutation({ mutationFn: exportSymbolTemplate });
export const useCreateSymbolRewardMutation = () => useMutation({ mutationFn: createSymbolReward });
export const useUpdateSymbolRewardMutation = () => useMutation({ mutationFn: updateSymbolReward });
export const useDeleteSymbolRewardMutation = () => useMutation({ mutationFn: deleteSymbolReward });
export const useExportSymbolRewardTemplateMutation = () => useMutation({ mutationFn: exportSymbolRewardTemplate });
export const useGetConfigSymbolMutation = (params, headers) => useMutation({ mutationFn: () => getConfigSymbol(params, headers) });
export const useGetConfigSymbolRewardMutation = (params, headers) => useMutation({ mutationFn: () => getSymbolReward(params, headers) });

// Query
export const useQueryGetConfigSymbol = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_CONFIG_SYMBOL', params], queryFn: () => getConfigSymbol(params), ...options });
export const useQueryGetSymbolReward = (params = {}, options = {}) =>
  useQuery({ queryKey: ['GET_SYMBOL_REWARD', params], queryFn: () => getSymbolReward(params), ...options });
