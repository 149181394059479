import { Box, Table, Tbody, Td, Text, Th, Thead, Tr, Flex, Button, useDisclosure } from '@chakra-ui/react';
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IoMdCopy } from 'react-icons/io';
import { formatDate, formatNumber } from 'utils/helpers';
import useCopyToClipboard from 'hooks/use-copy-to-clipboard';
import { toast } from 'components/Toast';
import BetDetailModal from './BetDetailModal';

const StatisticsTable = ({ tableData, handleUpdateCategory, refetch, handleShowMemberDetail }) => {
  const [sorting, setSorting] = useState([]);
  const [betDetail, setBetDetail] = useState(null);
  const columnHelper = createColumnHelper();
  const history = useHistory();
  const [, copy] = useCopyToClipboard();
  const { isOpen: isDetailModalOpen, onOpen: onOpenDetailModal, onClose: onCloseDetailModal } = useDisclosure();

  // const handleRowClick = (ticket, type) => {
  //   handleUpdateCategory(ticket, type);
  // };

  const handleViewClick = (idMember, ModalType) => {
    handleShowMemberDetail(idMember, ModalType);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        header: 'STT',
        cell: info => info.row.index + 1,
      }),
      columnHelper.accessor('_id', {
        header: 'Mã đơn cược',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            <Box
              color="blue.400"
              cursor="pointer"
              onClick={() => {
                setBetDetail(info?.row?.original);
                onOpenDetailModal();
              }}
            >
              {info.getValue()?.game_id || '--'}
            </Box>
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue()?.game_id);
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      columnHelper.accessor('played_at', {
        header: 'Bắt đầu cược',
        cell: info => (
          <Flex direction="column">
            <Text>{formatDate(info.getValue(), 'HH:mm:ss')}</Text>
            <Text>{formatDate(info.getValue(), 'DD/MM/YYYY')}</Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('played_at_1', {
        header: 'Kết thúc cược',
        cell: info => (
          <Flex direction="column">
            <Text>{formatDate(info?.row?.original?.played_at, 'HH:mm:ss')}</Text>
            <Text>{formatDate(info?.row?.original?.played_at, 'DD/MM/YYYY')}</Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('brand', {
        header: 'Nhà điều hành',
        cell: info => info.getValue() || '--',
      }),
      columnHelper.accessor('username', {
        header: 'Username',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            {info.getValue() || '--'}
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue());
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      columnHelper.accessor('encodeUsername', {
        header: 'Tên HV mã hoá',
        cell: info => (
          <Flex alignItems="center" justifyContent="center" gap="8px">
            {info.getValue() || '--'}
            <Box
              cursor="pointer"
              onClick={() => {
                copy(info.getValue());
                toast.showMessageSuccess('Sao chép thành công');
              }}
            >
              <IoMdCopy />
            </Box>
          </Flex>
        ),
      }),
      // columnHelper.accessor('createdAt', {
      //   header: 'Thể loại',
      //   cell: info => info.getValue(),
      // }),
      columnHelper.accessor('gameName', {
        header: 'Trò chơi',
        cell: info => info.getValue(),
      }),
      columnHelper.accessor('totalBetAmount', {
        header: 'Tiền cược',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('totalWinAmount', {
        header: 'Tiền khách thắng',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('totalNetProfitAmount', {
        header: 'Lợi nhuận nhà điều hành',
        cell: info => formatNumber(info.getValue() || 0),
      }),
      columnHelper.accessor('status', {
        header: 'Trạng thái',
        cell: info => 'Đã đóng',
      }),
    ],
    [tableData]
  );

  const table = useReactTable({
    data: tableData || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <Table>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <Th textAlign="center" key={header.id} minW="120px" bg="gray.100" textColor="#000">
                  {header.isPlaceholder ? null : (
                    <Box
                      isTruncated
                      cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()] ?? null}
                    </Box>
                  )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {isEmpty(table.getRowModel().rows) ? (
            <Tr>
              <Td textAlign="center" colSpan={12}>
                Không có dữ liệu
              </Td>
            </Tr>
          ) : (
            table.getRowModel().rows.map(row => (
              <Tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <Td textAlign="center" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
      {isDetailModalOpen && (
        <BetDetailModal
          betDetail={betDetail}
          isOpen={isDetailModalOpen}
          onClose={() => {
            setBetDetail(null);
            onCloseDetailModal();
          }}
        />
      )}
    </>
  );
};

export default StatisticsTable;
