import { Button, Card, CardBody, CardHeader, Flex, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { ModalType } from 'constants/common';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import CreateGameModal from './components/CreateGameModal';
import GameTable from './components/Table';
import { useQueryGetListGame } from 'services/game';
import Pagination from 'components/Pagination/Pagination';

function Games() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [gameDetail, setGameDetail] = useState(null);
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
    }),
    [onOpenCreateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
    }),
    [onCloseCreateModal]
  );

  const { data: listGameData, refetch } = useQueryGetListGame({ ...filter }, { enabled: CookieStorage.isAuthenticated() });

  const handleUpdateItem = (gameData, modalType) => {
    setGameDetail(gameData);
    openModal?.[modalType]?.();
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setGameDetail(null);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold" textTransform="uppercase">
                    QUẢN LÝ HỆ THỐNG {'>'} Trò chơi
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_ADMIN'}>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </AuthorizationComponentWrapper>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <GameTable games={listGameData?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
            </Stack>
            {!isEmpty(listGameData?.data) && (
              <Flex justifyContent={'flex-end'}>
                <Pagination
                  page={listGameData?.pagination?.page}
                  pageLength={listGameData?.pagination?.pageSize}
                  totalRecords={listGameData?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              </Flex>
            )}
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && (
        <CreateGameModal
          isOpen={isCreateModalOpen}
          games={listGameData?.data || []}
          gameDetail={gameDetail}
          onClose={handelCloseModal}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default Games;
