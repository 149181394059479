import { Button, Card, CardBody, CardHeader, Flex, Stack, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { ModalType } from 'constants/common';
import AuthorizationComponentWrapper from 'components/AuthorizationComponentWrapper/AuthorizationComponentWrapper';
import { useUserState } from 'context/UserContext';
import FreeSpinSettingTable from './components/Table';
import SettingFormModal from './components/SettingFormModal';
import { useQueryFreeSpinSetting } from 'services/setting';
import queryString from 'query-string';

function FreeSpinSetting() {
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  console.log('🚀 ~ FreeSpinSetting ~ searchParams:', searchParams);
  const [settingDetail, setSettingDetail] = useState(null);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { userInfo } = useUserState();

  useEffect(() => {
    if (!CookieStorage.isAuthenticated()) {
      return history.push('/auth/sign-in');
    }
  }, []);

  const { isOpen: isCreateModalOpen, onOpen: onOpenCreateModal, onClose: onCloseCreateModal } = useDisclosure();
  const openModal = useMemo(
    () => ({
      [ModalType.Add]: onOpenCreateModal,
    }),
    [onOpenCreateModal]
  );
  const closeModal = useMemo(
    () => ({
      [ModalType.Add]: onCloseCreateModal,
    }),
    [onCloseCreateModal]
  );

  const { data: settingData, refetch } = useQueryFreeSpinSetting({ ...filter }, { enabled: CookieStorage.isAuthenticated() });

  const handleUpdateItem = (setting, modalType) => {
    openModal?.[modalType]?.();
    setSettingDetail(setting);
  };

  const handelCloseModal = modalType => {
    closeModal?.[modalType]?.();
    setSettingDetail(null);
  };

  return (
    <>
      <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
        <Card p="16px" mb="24px" bg="#fff">
          <CardHeader p="12px 5px" mb="12px">
            <Flex justifyContent={'space-between'}>
              <Flex direction={'column'}>
                <Flex direction="column" gap={'30px'}>
                  <Text fontSize="xl" color={textColor} fontWeight="bold">
                    Thiết lập lượt quay miễn phí {searchParams?.gameName}
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                <AuthorizationComponentWrapper groupPermission={userInfo?.permissions} groupName={'CREATE_ADMIN'}>
                  <Button bg="#3182ce" color="#fff" _hover={{ bg: '#67a1d7' }} onClick={onOpenCreateModal}>
                    <Text fontSize="md" fontWeight="bold" cursor="pointer">
                      Thêm
                    </Text>
                  </Button>
                </AuthorizationComponentWrapper>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody overflowX="auto">
            <Stack overflow={'auto'}>
              <FreeSpinSettingTable games={settingData?.data || []} refetch={refetch} handleUpdateItem={handleUpdateItem} />
            </Stack>
          </CardBody>
        </Card>
      </Flex>
      {isCreateModalOpen && (
        <SettingFormModal isOpen={isCreateModalOpen} settingDetail={settingDetail} onClose={handelCloseModal} refetch={refetch} />
      )}
    </>
  );
}

export default FreeSpinSetting;
