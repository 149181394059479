import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CookieStorage } from 'utils/cookie-storage';
import { useHistory } from 'react-router-dom';
import Pagination from 'components/Pagination/Pagination';
import { Select } from 'chakra-react-select';
import HistoryTable from './HistoryTable';
import { useParams, useLocation } from 'react-router-dom';
import { useQueryGetHistoriesMemberDetail } from 'services/member';
import { useQueryGetListGame } from 'services/game';
import { mappingOptionSelect } from 'utils/mapping';
import queryString from 'query-string';
import { isEmpty } from 'lodash';

function HistoriesMemberDetail() {
  const isLoggedIn = CookieStorage.isAuthenticated();
  const textColor = useColorModeValue('gray.700', 'white');
  const history = useHistory();
  const [gameOptions, setGameOptions] = useState([]);
  const [gameSelected, setGameSelected] = useState();
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const params = useParams();
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const { id } = params || {};
  const { date } = searchParams || {};

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  const { data: historiesDetail, refetch } = useQueryGetHistoriesMemberDetail({ ...filter, date, memberId: id }, { enabled: isLoggedIn });
  const { data: listGameData } = useQueryGetListGame();

  useEffect(() => {
    if (listGameData?.data) {
      setGameOptions(mappingOptionSelect(listGameData?.data));
    }
  }, [listGameData?.data]);

  const handleUpdateItem = (size, modalType) => {
    openModal?.[modalType]?.();
    setSizeEditing(size);
  };

  const handleSearch = () => {
    setFilter({
      ...filter,
      date,
      game_id: gameType?.value,
    });
  };

  const handleReset = () => {
    setFilter({
      pageIndex: 0,
      pageSize: 10,
    });
    setGameSelected(null);
  };

  const onChangeDate = type => date => {
    setFilterDate(prev => ({
      ...prev,
      ...(type === 'start' && { end: null }),
      [type]: date,
    }));
  };

  const handleShowMemberDetail = (idMember, modalType) => {
    openModal?.[modalType]?.();
    setIdMember(idMember);
  };

  return (
    <Flex direction="column" pt={{ base: '120px', md: '75px', lg: '100px' }}>
      <Card p="16px" mb="24px" bg="#fff">
        <CardHeader p="12px 5px" mb="12px">
          <Flex justifyContent={'space-between'}>
            <Flex direction={'column'}>
              <Flex direction="column" gap={'30px'}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  {`Lịch sử chi tiết chơi của hội viên ngày: ${date}`}
                </Text>
              </Flex>
              <Flex justifyContent={'space-between'} alignItems={'end'} gap={'20px'} mt={'20px'}>
                <Stack>
                  <Flex alignItems={'center'} gap={'20px'} flexWrap={{ base: 'wrap', md: 'nowrap' }}>
                    {/* <FormControl minWidth={{ base: 'full', sm: '300px' }}>
                      <FormLabel>Tìm kiếm Hội Viên</FormLabel>
                      <Input value={searchTitle} onChange={e => setSearchTitle(e.target.value)} />
                    </FormControl> */}
                    <FormControl>
                      <FormLabel h={'21px'}>Trò chơi</FormLabel>
                      <Select
                        placeholder="Chọn trò chơi"
                        isClearable
                        menuShouldBlockScroll
                        value={gameSelected}
                        onChange={e => {
                          setGameSelected(e);
                        }}
                        options={gameOptions}
                      ></Select>
                    </FormControl>
                    <Button px={10} maxH="40px" alignSelf={'end'} onClick={handleReset}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Cài lại
                      </Text>
                    </Button>
                    <Button variant="primary" px={10} maxH="40px" alignSelf={'end'} onClick={handleSearch}>
                      <Text fontSize="md" fontWeight="bold" cursor="pointer">
                        Tìm kiếm
                      </Text>
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto">
          <Stack overflow={'auto'}>
            <HistoryTable
              tableData={historiesDetail?.data || []}
              handleUpdateCategory={handleUpdateItem}
              refetch={refetch}
              handleShowMemberDetail={handleShowMemberDetail}
            />
          </Stack>
          {!isEmpty(historiesDetail?.data) && (
            <Flex justifyContent={'flex-end'}>
              <Pagination
                page={historiesDetail?.pagination?.page}
                pageLength={historiesDetail?.pagination?.pageSize}
                totalRecords={historiesDetail?.pagination?.count}
                onPageChange={(page, pageLength) => {
                  setFilter({
                    ...filter,
                    pageSize: pageLength,
                    pageIndex: page - 1,
                  });
                }}
              />
            </Flex>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default HistoriesMemberDetail;
