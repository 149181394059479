import React, { useEffect } from 'react';
import { Box, Flex, Heading, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { CookieStorage } from 'utils/cookie-storage';
import { useQueryGetSummaryStatisticsMember } from 'services/statistics';
import SummaryStatisticMemberChart from 'components/SummaryStatisticMemberChart';

export default function Dashboard() {
  const textColor = useColorModeValue('gray.700', 'white');
  const isLoggedIn = CookieStorage.isAuthenticated();
  const history = useHistory();
  const { data: statistic } = useQueryGetSummaryStatisticsMember({}, { enabled: isLoggedIn ? true : false });

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push('/auth/sign-in');
    }
  }, [isLoggedIn, history]);

  return (
    <>
      <Flex flexDirection="column" pt={{ base: '120px', md: '75px' }}>
        <Box>
          <Box
            backdropFilter="blur(21px)"
            boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
            bg="white"
            borderRadius={'10px'}
            py={'32px'}
            px={'16px'}
          >
            <Heading fontWeight={700} fontSize={18} color={textColor}>
              Tổng hợp cố liệu 5 ngày gần nhất
            </Heading>
            <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="24px" mb="20px" mt="20px">
              <SummaryStatisticMemberChart
                title="Số lượng hội viên đăng nhập"
                labelChart="Số lượng hội viên"
                dataChart={statistic?.data?.dataTotalMember}
                redirectUrl="/admin/statistics/member"
              />
              <SummaryStatisticMemberChart
                title="Số lượng đơn cược"
                labelChart="Số lượng đơn cược"
                dataChart={statistic?.data?.dataTotalBet}
                redirectUrl="/admin/statistics/bet"
              />
              <SummaryStatisticMemberChart
                title="Tổng tiền cược"
                labelChart="Tiên cược"
                dataChart={statistic?.data?.dataTotalBetAmount}
                redirectUrl="/admin/statistics/bet"
              />
              <SummaryStatisticMemberChart
                title="Tổng tiền lợi nhuận"
                labelChart="Lợi nhuận"
                dataChart={statistic?.data?.dataTotalNetProfit}
                redirectUrl="/admin/statistics/bet"
              />
            </SimpleGrid>
          </Box>
        </Box>
      </Flex>
    </>
  );
}
